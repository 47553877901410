import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

export default class CarriersTermsAndConditions extends Component{
    render() {
        return (
            <div>
                <Navbar />
                <div className="faq-content">
                    <div className="" style={{padding:"0 30px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                                <h2>Terms and Conditions</h2>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                </div>
                <section class="faq-section-terms">
	<div class="" style={{padding:"0 30px "}}>
		<div class="row">
			<div class="col-sm-1">

			</div>
			<div class="col-sm-10">
				<h4 class='text-center'>JHAKI – TERMS OF SERVICE AGREEMENT FOR CARRIERS</h4>
				<hr/>
				<p>This Terms of Service Agreement (the “Agreement”) describes the terms by which “JHAKI LOGISTICS INNOVATION LIMITED ” offers to you, as a Carrier, access to its website www.gojhaki.com (the “Site”) and the associated mobile software application platform owned and operated by JHAKI (“Mobile App”). Jhaki provides an online and mobile platform (the “Service”) to connect Shippers with Carriers for the transportation of cargo whereby Shippers can submit a request for the transportation of cargo (“Shipment”).</p>
				<p> Carriers can accept such requests, and both Shippers and Carriers can track such requests. The Service includes access to all applications, content and downloads offered by Jhaki, including the Site, Mobile App, and associated user content. Jhaki does not assess the suitability, legality, regulatory compliance, quality or ability of any Shipper or shipped items scheduled through the use of the Service, and jhaki makes no warranty regarding the same.</p>
				<p>This Agreement sets forth the terms for use of the Service by Carriers, including the terms governing transportation services provided by Carriers. By signing up and registering with Jhaki or by accessing or using the Service, you are accepting this Agreement, on behalf of yourself or the company, entity or organization that you represent, and you represent and warrant that you have the right, authority, and capacity to enter into this Agreement, on behalf of yourself or the company, entity or organization that you represent.</p>
				<p> You may not access or use the Service or accept this Agreement if you are not at least 18 years old. Please read this Agreement carefully before using the Service. Use of the Service is conditioned on your agreement to all of the terms and conditions contained in the Agreement, including the policies and terms linked to or otherwise referenced in the Agreement, all of which are hereby incorporated into the Agreement.</p>
				<p>Please refer to our <Link to="/privacy_policy">Privacy Policy</Link> for information about how we collect, use and disclose information about users of the Service.
				IF YOU DO NOT SO AGREE, YOU SHOULD DECLINE THIS AGREEMENT, IN WHICH CASE YOU ARE PROHIBITED FROM ACCESSING OR USING THE SERVICE.</p>


                <h4>1.DEFINED TERMS</h4>
					<strong>1.“Partner Carrier” or “Carrier”</strong>
					<p>Carrier means a truck, barge or rail freight service provider registered with the appropriate regulatory authority including federal and applicable state governments to haul cargo for hire. A Carrier who accesses or uses the Service is an independent contractor and remains solely responsible for screening, selecting, hiring, training, supervising, managing, assigning, and dispatching its drivers; as well as for the inspection and maintenance of its motor vehicle equipment and accessories.</p>
					<p> A Carrier is solely responsible for its own actions, omissions, training, oversight, compliance with regulatory and safety requirements, and all management of Carrier’s equipment, services, drivers, employees, contractors, agents and servants.</p>
					<p> A Carrier maintains sole control over the methods and results by which it performs cargo transportation services, and retains the sole duty to provide, maintain, manage and control the equipment, personnel, and expertise required to transport your cargo. Jhaki is not an agent of any Carrier, and no Carrier is an agent of Jhaki.</p>
					<p>JHAKI IS NOT A CARRIER. NO INTERPRETATION OF WRITTEN OR ORAL REMARKS IN ANY AGREEMENT OR DOCUMENT SHALL BE CONSTRUED TO IMPLY JHAKI IS A CARRIER, OR THAT JHAKI IS SUBJECT TO THE REGULATORY OR LEGAL REQUIREMENTS OR LIABILITIES OF A CARRIER. JHAKI HAS NO RESPONSIBILITY OR LIABILITY FOR ANY TRANSPORTATION OR CARRIER SERVICES PROVIDED TO ANY SHIPPER OR ANY OTHER PARTY USING THE SERVICES.</p>
					<strong>2.“Jhaki”</strong>
					<p>Jhaki is a limited liability company duly registered with the Corporate affairs commission ( CAC) of Nigeria . Jhaki’s sole obligation is to arrange transportation of cargo by a Carrier that is appropriate and authorized to operate by all applicable governmental agencies.</p>
					<p> As a freight broker, Jhaki does not take possession, custody or control of any cargo. Jhaki does not assume any liability, possessory rights or obligations, and assumes no financial responsibility whatsoever, for cargo, including loss, theft, damage or delayed delivery thereof.</p>
					<p>Jhaki offers a platform to connect Shippers and Carriers, but does not provide actual transportation services or act in any way as a Carrier. It is the Carrier’s obligation to provide transportation services, which may be scheduled through the use of the Service. Jhaki has no responsibility for any shipping services provided to you as a Shipper by any Carrier.</p>

					<strong>3.“Shipper”</strong>
					<p>A shipper is an individual or a that owns, possesses, or otherwise have rights to transport cargo it seeks to ship by way of the Service</p>
					<strong>4.“User”</strong>
					<p>A User is any individual or company who accesses or uses the Service.</p>
					<strong>5.“User Content”</strong>
					<p>User Content means any and all information, data, textual, audio, and/or visual content, commentary and feedback related to the Service, ratings, reviews and other content that a User submits to, or uses with, the Service.</p>
					<p> User Content includes the information provided in a Shipment request or acceptance. User, including Carrier, warrants:</p>
					<p> (i) the completeness and accuracy of all User Content posted to the Service; and</p>
					<p> (ii) that User will provide any and all information as prompted or requested by the Service.</p>
					<strong>6.Independent Contractor</strong>
					<p>This Agreement does not make you an agent, legal representative, joint venture, or partner of Jhaki for any purpose.</p>
					<p> You understand and agree that you will act as an independent contractor and are in no way authorized to make any contract, warranty or representation on behalf of Jhaki, or to create any obligation express or implied on behalf of Jhaki.</p>
					<p> It is expressly agreed and understood that you shall not be considered under this Agreement as having any employment status with Jhaki, or as being entitled to any plans, distributions, or benefits extended by Jhaki to its employees.</p>

					<h4>2.DESCRIPTION OF SERVICES</h4>
					<p>Jhaki provides a platform via the Mobile App and our Website (defined above as the “Service”) so that Shippers may submit Shipments through the Service.</p>
					<p>Once Jhaki accepts a Shipment for posting, the Shipment’s details, as provided by the Shipper, will be posted to the Service. The Shipper may cancel a Shipment at any time prior to a Carrier accepting it. You may view a list of Shipments available to you at any time and accept a Shipment through the Service. Once you accept a Shipment, you will be assigned to perform transportation services related to the Shipment.</p>
					<p>Jhaki will notify the Shipper that the Shipment has been accepted. It will also notify you that the Shipment has been assigned to you; PROVIDED, however, that Jhaki does not guarantee that your attempt to accept any Shipment will be successful. Jhaki assigns Shipments to the first Carrier which accepts the posting. If the original Carrier assignment fails for whatever reason, the Shipment will be re-posted to the Service.</p>
					<p>Unless otherwise agreed, freight charges stated in the App and agreed to by your acceptance of Shipments hereunder include the transportation of the cargo from origin to destination; the cost of any fuel, tolls, barge handling charges or other expenses related to the operation or maintenance of your equipment; and any other specialized services or equipment contemplated in the load tender (including, but not limited to amounts for refrigerated trailers, lift-gate service, loading or unloading, etc.). If any additional services not contemplated at the time a Shipment is posted are provided by Carrier. </p>
					<p> Carrier will use its best efforts to provide Jhaki advance notice of any and all unspecified ancillary services or costs incurred so that Jhaki may attempt to obtain Shipper approval of such charges. Absent express prior agreement, Jhaki does not guarantee that invoices for accessorial and/or ancillary services will be paid.</p>
					<p>Once a Shipment is completed, you shall post to the Service a proof of delivery signed by the authorized recipient immediately.
                    Jhaki may, as a convenience and value added service, provide you through the Service with access to GPS services, Google Maps or similar service to suggest routing. However, any such routing information is for your convenience only. It is not instructional or mandatory.</p>

					<h4>3.	 YOUR RESPONSIBILITIES</h4>
<strong>1.Documents due at registration</strong>
<p>Upon registering with the Service, you shall provide Jhaki:</p>
<p>(i) updated details of each carrier equipment, Carrier’s particulars, and every other document as may be updated on the platforms from time to time.</p>
<p> (ii)updated compliance documents, including but not limited to licenses to be gotten in line with the Nigerian Transportation (Infrastructure and logistics) laws </p>
<p> (iii) proof of insurance as described in Section 3.2 below.</p>
<strong>2.Insurance</strong>
<p>You shall procure and maintain, at your sole cost and expense, with a reputable and financially responsible insurance underwriters maintaining a rating of B+ or higher, acceptable to Jhaki, the following insurance coverages:</p>
<p>1.	Automobile/barge Liability insurance to include any automobile/barge, or all owned, non-owned and hired automobiles, covering bodily injury (including injury resulting in death) and loss of or damage to property in an amount not less than NGN 40,000,000 per incident. If applicable law, rule or regulation requires Carrier to maintain amounts in excess of these limits, Carrier shall provide such higher limits.</p>
<p>2.	Comprehensive Goods in Transit (GIT) insurance in an amount not less than NGN40,000,000 per incident, and shall not contain any exclusions for employee theft or dishonesty, unattended or unattached trailers, or otherwise likely to result in denial of claims (including commodity specific exclusions, exclusions for corrosion, or exclusions related to use of refrigerated equipment, including breakdown). If Carrier maintains limits in excess of these amounts, Carrier may qualify for Shipments not otherwise available to Carriers only maintaining such minimum amounts.</p>
<p>3.	Workers compensation in accordance with any and all applicable laws, rules and regulations.</p>
<p>4.	Any other insurance that may be required by Jhaki or any applicable federal, state or local laws, rules, regulations or ordinances.</p>
<p>You shall provide to Jhaki certificates of insurance and, on request, copies of all policies and endorsements. Jhaki, Inc's designated insurance monitoring service will be listed as a certificate holder. You will ensure Jhaki is provided notice of cancellation or modification of any insurance required under this agreement at least 30 days in advance of any cancellation or modification of the required insurance. Carrier will not provide services under this Agreement at any time that Carrier is not in compliance with these obligations related to insurance.</p>
<strong>3.Subcontractors/Co-Brokers</strong>
<p>You shall not re-broker, co-broker, subcontract, assign, or cause or permit any other person or entity to perform any of your obligations hereunder, or cause or permit any Shipment tendered hereunder to be transported by any other third-party carrier, or any other substitute mode of transportation, without the express written consent of Jhaki and the Shipper.</p>
<p> If Carrier breaches this provision, without limiting any other right of Jhaki or the Shipper, Carrier shall remain fully liable pursuant to this Agreement as if it had transported the Shipment on vehicles operating under its for hire motor carrier authority (including liability for cargo loss and damage claims and including the duty to defend, indemnify and hold harmless against the acts and omissions of Carrier and its contractors).</p>
<p> Carrier acknowledges and agrees if any third party makes a claim against Jhaki or the Shipper with respect to Shipments tendered to Carrier for transportation hereunder, Jhaki may pay such third party directly and shall have no duty to pay Carrier with respect to any such Shipment.</p> <p>Jhaki shall have the right to offset the amount of payment to such third party against any funds due and owing to Jhaki due to Carriers breach of this provision. Further, Jhaki shall have the right to claim and collect any damages, including consequential, incidental, and indirect damages from Carrier arising out of a breach of this provision.</p>
<strong>4.Compliance With Laws</strong>
<p>Carrier acknowledges that it is authorized to provide transportation of any Shipments requested or obtained through the Service as a supplier in accordance with any and all applicable laws, rules and regulations. With respect to the transportation services provided under this Agreement, including any drivers you use, you shall comply with all applicable federal, state and local laws, rules, regulations and ordinances.</p>
<p> You shall defend, indemnify and hold Jhaki and Shippers harmless from and against any and all fines, penalties, judgments, liabilities, expenses and costs of any nature resulting from your failure to comply with all such laws, rules, regulations and ordinances.</p>
<p>The foregoing obligations include, but are not limited to, compliance with all applicable laws, rules and regulations applicable to: transportation of Hazardous Materials to the extent that any shipments hereunder constitute Hazardous Materials; security regulations; owner/operator lease regulations; loading and securement of freight regulations; implementation and maintenance of driver safety regulations including, but not limited to, hiring, controlled substances and alcohol testing, and hours of service regulations; sanitation, temperature, and contamination requirements for transporting food, perishable, and other products, qualification and licensing and training of drivers; implementation and maintenance of equipment safety regulations; maintenance and control of the means and method of transportation including, but not limited to, performance of drivers; all applicable insurance laws and regulations including, but not limited to, workers’ compensation.</p>

<strong>5.Equipment</strong>
<p>You warrant that you shall perform all transportation services pursuant to this Agreement with equipment that is regularly maintained and is in good order, condition and repair and that meets with all applicable federal and state laws, rules and regulations. You will not supply equipment that has been used to transport hazardous wastes of any kind, including, solid, liquid, or hazardous. You will furnish equipment for transporting cargo which is clean, dry, leak proof, free from harmful or offensive odor, sanitary, and free of any contamination, suitable for the particular commodity being transported and which will not cause in whole or in part adulteration of the commodity.</p>
<p>Unless a trailer is pre-loaded and sealed prior to Carrier’s arrival at destination, and the applicable bill of lading bears a “shipper load and count”, “SLC” or similar designation, Carrier is solely responsible for ensure that all equipment has been properly loaded, secured, blocked and braced. You acknowledge that Jhaki will never be in possession of any cargo being transported in connection with use of the Service, and that Jhaki will not be responsible or have any role in the securement of cargo for transportation.</p>
<strong>6.Non-Solicitation</strong>
<p>During the term of this Agreement, and for a period of one (1) year from its termination, you shall neither initiate nor accept any direct or indirect business relationship with any Shipper where Shipments to or from such Shipper were first transported pursuant to the Service, or in connection with use of the Service. Your obligation in this regard extends to instances where a Shipper contacts you and seeks to establish a business relationship that does not include Jhaki.</p>
<p> This provision shall continue in force beyond termination of this Agreement for one (1) year subsequent to termination.
If you book or otherwise make available any direct or indirect business relationship with any Shipper that was first introduced to you by Jhaki in violation of this Agreement, you shall be jointly and severally liable with the Shipper to Jhaki for each such violation in an amount equal to twenty percent (20%) of all revenues paid by the Shipper to you.</p>

<strong>7.Carrier Liability for Cargo Loss or Damage</strong>
<p>	1.	A Carrier may impose time limits for filing of loss and damage claims, as well as for filing any action at law for cargo loss or damage. We recommend you consult with a qualified attorney regarding any action you might wish to pursue against a Carrier based on lost, damaged, delayed or destroyed cargo.</p>
<p>2.	Should you wish Jhaki’s assistance with or other participation in any claim for lost, damaged, delayed or destroyed cargo, you should file with Jhaki its written notice of claim to Carrier. Jhaki may facilitate processing of cargo claims; PROVIDED, however, you understand and agree that, notwithstanding Jhaki’s participation in or assistance with any cargo claim, Jhaki is not liable for any cargo loss which was not proximately caused by Jhaki’s own wrongdoing.</p>
<p>3.	Shipper shall have sole discretion as to whether to allow salvage of any damaged Shipment. If no salvage is allowed, Carrier shall not be entitled to a credit for salvage value. Any expenses incurred in preparation of goods for salvage shall be borne by Carrier.</p>
<p>4.	Carrier will abide by any cargo handling instructions communicated to Carrier by Jhaki or the Shipper, including any regarding provision of temperature controlled service. </p>
<p>If cargo is tendered and a reasonable person would understand such cargo to require controlled temperature service, and no such service has been requested, Carrier shall contact Jhaki immediately and in any event, prior to loading any such cargo onto Carrier’s conveyance.</p>
<p> Without limiting the foregoing, if Carrier is providing service with respect to commodities requiring temperature control, Carrier shall ensure that its equipment is pre-cooled to required temperature ranges prior to or at the time of loading, and shall ensure that temperature is maintained at all times within specified temperature ranges.</p>
<p> Carrier shall maintain the ability to provide a downloadable report of temperature during transit, and will retain such records for no less than two (2) years from the date of delivery, which records will be made available to Jhaki or Shipper upon request. </p>
<p>Carrier acknowledges and agrees that failure to abide by instructions regarding handling of food or evidence of possible unauthorized access to shipments may result in rejection of shipments due to possible adulteration or contamination.</p>

<p>5.	Any attempt to limit your liability for lost, destroyed, damaged or delayed Shipments, including, but not limited to, via provisions contained in any bill of lading, delivery receipt or tariff shall be deemed null and void. Exclusions in your insurance coverage shall not exonerate you from this liability.</p>
<strong>8.	Shipping Documents</strong>
<p>You shall not insert “Jhaki” or “Jhaki Innovation Logistics Limited.” on any receipt, bill of lading, manifest, or other shipping document. In the event you do so, such insertion shall be deemed to be for your convenience, or due to your oversight, and shall not operate to alter Jhaki’s status as a Broker, or the Carrier’s status as the responsible Carrier.</p>
<p> The provisions set forth in any shipping document maintained by Carrier (including, but not limited to, any bill of lading, proof of delivery, motor carrier tariff, rate confirmation sheet or other documentation) shall not apply to any transportation performed pursuant to Compliance with Instructions</p>
<strong>9.	Compliance with Instructions</strong>
<p>Carrier shall comply with any and all instructions regarding the handling of the Shipment which are communicated to Carrier by Shipper or Jhaki, whether pursuant to the Service, on the applicable bill of lading, or via other load tender documentation provided to the Carrier. In addition, Carrier shall, and shall cause its drivers, to comply with facility rules in effect at any locations where Carrier is performing pick-up or delivery services.</p>
<strong>10.</strong>
<p>10.	You understand and agree that Jhaki, from time to time, enters into master transportation contracts or like documents with certain shippers. Such contracts often provide that their terms preempt and govern over any term within a bill of lading or other shipping document that conflicts or is otherwise inconsistent with the contracts.</p>
<p> To the extent any transport you undertake hereunder is governed by a contract between Jhaki and a shipper containing such a clause, you agree that any bill of lading or other document you issue shall be subordinate to and preempted by such contract’s terms. Jhaki will advise you on request as to whether any such master transportation contract or like document containing a preemptive clause exists with respect to specific shipments you undertake.</p>
<p> You further understand and agree that such contracts or like documents may contain terms restricting the extent to which you may be entitled to collected charges for accessorial and ancillary services, in which event Jhaki will not pay related charges notwithstanding any advance notice.</p>
<strong>11.	Indemnity</strong>
<p>CARRIER SHALL DEFEND, INDEMNIFY, AND HOLD JHAKI, THE SHIPPER, AND EACH OF THEIR AFFILIATED ENTITIES HARMLESS FROM AND AGAINST, AND SHALL PAY AND REIMBURSE, ANY AND ALL DIRECT OR INDIRECT LOSS, LIABILITY, DAMAGE, CLAIM, FINE, COST OR EXPENSE, INCLUDING REASONABLE ATTORNEY’S FEES, ARISING OUT OF OR IN ANY WAY RELATED TO THE PERFORMANCE OR BREACH OF THIS AGREEMENT BY CARRIER, ITS EMPLOYEES, SUBCONTRACTORS OR INDEPENDENT CONTRACTORS (COLLECTIVELY, THE “CLAIMS”), INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR OR RELATED TO PERSONAL INJURY (INCLUDING DEATH), PROPERTY DAMAGE AND CARRIER’S POSSESSION, USE, MAINTENANCE, CUSTODY OR OPERATION OF THE EQUIPMENT; PROVIDED, HOWEVER, THAT CARRIER’S INDEMNIFICATION AND HOLD HARMLESS OBLIGATIONS UNDER THIS PARAGRAPH WILL NOT APPLY TO THE PRORATED EXTENT THAT ANY CLAIM IS DIRECTLY AND PROXIMATELY CAUSED BY THE NEGLIGENCE OR OTHER WRONGFUL CONDUCT OF THE PARTY TO BE DEFENDED, INDEMNIFIED OR HELD HARMLESS. CARRIER HEREBY EXPRESSLY WAIVES ANY EXCLUSIVE REMEDY DEFENSE, INCLUDING, BUT NOT LIMITED TO, THOSE AVAILABLE UNDER ANY WORKERS’ COMPENSATION OR OTHER OCCUPATIONAL ACCIDENT STATUTORY REGIME, TO THE EXTENT NECESSARY TO EFFECTUATE CARRIER’S OBLIGATIONS UNDER THIS PROVISION.</p>
<strong>12.	Additional Obligations</strong>
<p>Carrier will provide exclusive use of equipment being used to handle any Shipment and will not allow the cargo of any third party to be transported on Carriers equipment while such equipment is being used to haul any Shipment under this Agreement. At no time will Carrier allow any trailing equipment being used to transport any Shipment become unattached from the power unit being used to transport such cargo.</p>

<h4>4.CARRIER SELECTION</h4>
<p>Jhaki is not responsible in any way for the acts and/or omissions of Carriers or their drivers.
Jhaki provides a means for Shippers and Carriers to rate and review each other and to have those ratings made available to other Users. We do not express any opinion, nor does Jhaki make any assurances regarding, the truth or accuracy of any User reviews or ratings. Jhaki does not regularly monitor or remove reviews or ratings, or any portion thereof, unless they contain Content we deem inappropriate in our sole discretion.
</p>
<h4>5.PAYMENT TERMS</h4>
<strong>1.	Rates and Payment</strong>
<p>For each Shipment, Shipper will pay Jhaki the freight charge quoted to the Shipper upon acceptance of the applicable Shipment on the Service (“Carrier Fee”), as well as additional amounts, if any, paid by the Shipper for additional services provided by Carrier with respect to a Shipment.</p>
<strong>2.	General Payment Terms</strong>
<p>You agree that you are responsible for the collection and/or payment of all taxes, which you may be liable for in any jurisdiction arising from your use of the Service. Jhaki is not responsible for collecting, reporting, paying, or remitting to you any such taxes.</p>
<p>Jhaki shall pay freight charges quoted to you on the Service regardless of whether Shippers pay Jhaki. In exchange for this guarantee of payment, you shall not invoice or otherwise attempt to collect any amounts related to services provided with respect to any Shipment from any Shipper or any other third party; Carrier shall look solely to Jhaki for payment of freight charges hereunder.</p>
<p> You hereby waive any right you may otherwise have to proceed or commence any action against any Shipper for the collection of any freight bills arising out of transportation services hereunder. Furthermore, Carrier waives any and all lien rights with respect to any Shipment and if any lien is claimed with respect to any such Shipment by Carrier or a third party to which Carrier tenders such Shipment, Carrier shall immediately take such action as is necessary to satisfy such lien.</p>
<strong>3.	Service Incentive Payment</strong>
<p>Jhaki may, in its sole discretion, offer to Carrier a Service Incentive Payment (“SIP”).  The SIP, if any, will be quoted separately from the Carrier Fee.  To qualify for the SIP:</p>
<p> (i), Carrier must be in compliance with any and all applicable laws, rules and regulations at all times while performing services (including compliance with speed limits and hours of service regulations);</p>
<p> (ii) Carrier must deliver the cargo at the appointed time;</p>
<p> (iii) Carrier must provide a proof of delivery to Jhaki within twenty-four hours of delivery which indicates that the cargo was delivered without shortage, loss or damage; and</p>
<p> (iv) prior to payment, Jhaki must not have received any complaint or claim from the Shipper or receiver regarding Carrier’s services with respect to the load in question. </p>
<p> If Jhaki pays the SIP to Carrier, but later learns that the Carrier failed to abide by applicable law in performance of the services to which the SIP related, or if Jhaki subsequently receives a claim or complaint from the Shipper or receiver related to the services to which the SIP related, Carrier will refund the SIP upon demand.  Jhaki may offset the amount of such SIP against any funds due and owing to Jhaki.</p>
<strong>4.	Payment Facilitation and Processing</strong>
<p>All charges are facilitated through a third-party payment processing service provided by Guaranty Trust Bank (GTPay). Jhaki may replace its third-party payment processing services without notice to you.</p>
<h4>6.LICENSES</h4>
<strong>1.	Accounts</strong>
<p>In order to use certain features of the Service, you must register for an account with Jhaki (“Account”) and provide certain information as prompted by the registration and Shipment creation form. You represent and warrant that:</p>
<p> (a) all required registration information you submit is truthful and accurate; and </p>
<p>(b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by contacting Jhaki or following the instructions on the Service.</p>
<p> You are responsible for maintaining the confidentiality of your Account login credentials and are fully responsible for all activities that occur under your Account. </p>
<p>You agree to immediately notify Jhaki of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Jhaki is not liable for any loss or damage arising from your failure to comply with the above requirements.</p>
<strong>2.	Telephone Calls and Text Messages</strong>
<p>Upon registration for an account, you will be asked to provide us with a telephone number at which we can reach you. That number is required to connect Shippers with Carriers for the transportation of cargo and so that Jhaki can reach you with informational calls and SMS and/or MMS text messages related to the transportation of cargo.</p>
<p> The frequency of text messages that we send to you depends on your transactions with us and you consent to receive text messages sent through an automatic telephone dialing system. All calls to and from Jhaki may be monitored or recorded for quality and training purposes.</p>
<p>If you elect to receive promotional text messages in connection with your account, we may also send you promotional text messages and you consent to receive text messages sent through an automatic telephone dialing system.</p>
<p>All charges are billed by and payable to your wireless service provider. Please contact your wireless service provider for pricing plans and details. If you wish to opt out of such text messages, you may do so by following the "opt-out" instructions in the text message, or by editing your account settings. Message and data rates may apply. We will treat data collected through text messages in accordance with our Privacy Policy.
</p>
<strong>3.	Site</strong>
<p>Jhaki owns and retains ownership in the Site and all intellectual property therein. Subject to the terms of this Agreement, Jhaki grants you a limited, non-transferable, non-exclusive, revocable license to use the Site for your internal business use during the term of this Agreement.</p>
<strong>4.	Mobile App</strong>
<p>Jhaki owns and retains ownership in the Mobile App and all intellectual property therein. Subject to the terms of this Agreement, Jhaki grants you a limited, non-transferable, non-exclusive, revocable license to install and use the Mobile App, in executable object code format only, solely on your own handheld mobile device and for your internal business use during the term of this Agreement.</p>
<strong>5.	Restrictions</strong>
<p>The rights granted to you in this Agreement are subject to the following restrictions:</p>
<p>1.	You shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Service;</p>
<p>2.	You shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Service;</p>
<p>3.	You shall not access the Service in order to build a similar or competitive service; and</p>
<p>4.	Except as expressly stated herein, no part of the Service may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.</p>
<strong>6.	Limited Support</strong>
<p>Users may contact Jhaki’s technical support center for any support- related issues arising from the use of the Service by following the instructions on the Service.</p>


    <h4>7.INSURANCE</h4>
					<p>Jhaki agrees to maintain, at its own expense, at all times, at least the following insurance coverage amounts:</p>
					<p>General Liability: $277,778 (N100,000,000)<br/>
					Auto Liability: $41,667 (N15,000,000)<br/>
					Contingent Cargo Liability: $41,667 (N15,000,000)<br/></p>
					<p>Upon request, Jhaki may procure insurance coverage amounts that exceed these limits, and the evidence of such coverage shall be in the form of an insurance certificate provided to you on request. Jhaki’s maximum liability to you for any loss shall be limited to Jhaki’s insurance policy terms and conditions and the dollar amounts for coverage hereinbelow.</p>
					<p>Jhaki’s contingent cargo insurance is subject to the terms, conditions and certain limitations and/or exclusions as contained in the policy, and the terms, conditions and requirements as outlined within this Agreement, which are subject to change at any time. The existence of Jhaki’s contingent cargo insurance in no way shifts or places any legal or contractual liability on Jhaki, nor does it exonerate the your duties and liabilities under the 49 USC §14706 or this Agreement.</p>
					<h4>8.OWNERSHIP</h4>
					<p>Jhaki owns intellectual property rights in and to the Service, including but not limited to the Site, Mobile App, including all related software and servers, in and to our trademarks, service marks, trade names, logos, domain names, taglines and trade dress (collectively, the “Marks”). Users acknowledge and agree that Jhaki owns all right, title, and interest in and to the Service, including all intellectual property rights therein. Users understand and agree that without a written license agreement with Jhaki, Users may not make any use of the Marks. Except as expressly granted in this Agreement, all rights, title and interest in and to the Service, and in and to the Marks are reserved by Jhaki.</p>
					<h4>9.MODIFICATION OF THE SERVICE</h4>
					<p>Jhaki reserves the right, at any time, to modify, suspend, or discontinue the Service or any part thereof with or without notice. You agree that Jhaki will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service or any part thereof.</p>
					<h4>10.LIMITATIONS ON USE OF THE SERVICE</h4>
					<p>You agree not to use the Service to upload, transmit, display, or distribute any User Content that:</p>
					<p> (a) violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; or</p>
					<p> (b) is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature or which is harmful to minors in any way.</p>
					<p>In addition, you agree not to use the Service to:</p>
					<p> (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; </p>
					<p>(b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise;</p>
					<p> (c) harvest, collect, gather or assemble information or data regarding other Users, including e-mail addresses, without their consent;</p>
					<p> (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the Service or violate the regulations, policies or procedures of such networks;</p>
					<p> (e) attempt to gain unauthorized access to the Service, other computer systems or networks connected to or used together with the Service, through password mining or other means; </p>
					<p>(f) harass or interfere with another User’s use and enjoyment of the Service; or</p>
					<p> (g) introduce software or automated agents or scripts to the Service so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the Service.</p>
					<p>Jhaki reserves the right to review any User Content, investigate, and /or take appropriate action against you in its sole discretion, including removing or modifying User Content, terminating your Account, and/or reporting you to law enforcement authorities. However, Jhaki has no obligation, to monitor, modify or remove any User Content.</p>
					<h4>11.USER CONTENT</h4>
					<strong>1.	User Content</strong>
					<p>You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third party personally identifiable.</p>
					<p> You hereby represent and warrant that your User Content does not violate any provision of this Agreement. For the avoidance of doubt, User Content may include third party content you submit. You agree not to submit third party content unless you have the consent of the applicable third party owner of such content.</p>
					<p> You may not state or imply that your User Content is in any way provided, sponsored or endorsed by Jhaki. You acknowledge and agree that Jhaki is not responsible for any loss or damage resulting from anyone’s use or reliance on User Content and Jhaki makes no guarantees regarding the accuracy, completeness, usefulness currency, suitability, or quality of any User Content, and assumes no responsibility for any User Content.</p>
					<strong>2.	License</strong>
					<p>Users hereby grant, and represent and warrant that they have the right to grant, to Jhaki an irrevocable, nonexclusive, royalty-free and fully paid, sublicenseable, worldwide license, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels, now known or hereafter devised (including in connection with the Services and Jhaki's business and on third-party sites and services), without further notice to or consent from You, and without the requirement of payment to You or any other person or entity.</p>
					<p> All rights in and to the User Content not expressly granted to Jhaki in this Agreement are reserved by Users.</p>
					<strong>3.	Anonymous Data</strong>
					<p>Jhaki may create anonymous data records (“Anonymous Data”) from your User Content by using commercially reasonable efforts to exclude any and all information (such as company name) that makes the data identifiable to you. Jhaki may use and disclose Anonymous Data for any purpose, including improving the Service.</p>
					<strong>4.	Disclosure</strong>
					<p>Jhaki may share your User Content</p>
					<p> (a) with third party service providers; </p>
					<p>(b) if another company acquires Jhaki; and/or</p>
					<p> (c) to comply with relevant laws, to respond to subpoenas or warrants or assist in preventing any violation or potential violation of the law or this Agreement.</p>
					<strong>5.	Copyright Complaints and Copyright Agent</strong>
					<p>•	Jhaki respects the intellectual property of others, and expects Users to do the same. If you believe, in good faith, that any materials on the Services infringe upon your copyrights, please send the following information to Jhaki’s Copyright Agent at hello@gojhaki.com;</p>
					<p>•	A description of the copyrighted work that you claim has been infringed, including specific location on the Services where the material you claim is infringing is located. Include enough information to allow Jhaki to locate the material, and explain why you think an infringement has taken place;</p>
					<p>•	A description of the location where the original or an authorized copy of the copyrighted work exists – for example, the URL (Internet address) where it is posted or the name of the book in which it has been published;</p>
					<p>•	Your address, telephone number, and e-mail address;</p>
					<p>•	A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
					<p>•	A statement by you, made under penalty of perjury, that the information in your notice is accurate, and that you are the copyright owner or authorized to act on the copyright owner's behalf; and</p>
					<p>•	An electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest.</p>
					<p>•	We may terminate access, usage or subscription to the Site, as the case may be, for repeat infringers in appropriate circumstances.</p>

					<h4>THIRD PARTY SITES AND LOCATION INFORMATION</h4>
					<strong>1.	Third Party Sites</strong>
					<p>The Service might contain links to third party websites, services, and advertisements for third parties (collectively, “Third Party Sites”). Such Third Party Sites are not under the control of Jhaki and Jhaki is not responsible for any Third Party Sites. Jhaki does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites. You use all Third Party Sites at your own risk. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third Party Sites.</p>
					<strong>2.	Location Information</strong>
					<p>Location data provided by the Service is for basic location purposes only and is not intended to be relied upon in situations where precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage, or other loss. Neither Jhaki, nor any of its content providers, guarantees the availability, accuracy, completeness, reliability, or timeliness of location data displayed by the Services, whether provided by Jhaki, third party content providers, or Users.</p>
					<p>Geolocational data that You upload, provide, or post on the Services may be accessible to certain Users of the Services. You assume any and all risk of providing such data to other Users of the Services.</p>
					<h4>13.	 INDEMNITY</h4>
					<strong>1.	Indemnification of Jhaki</strong>
					<p>You agree to defend, indemnify and hold Jhaki (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of your</p>
					<p> (i) use of the Service,</p>
					<p> (ii) User Content,</p>
					<p> (iii) interaction with any other User,</p>
					<p> (iv) violation of this Agreement; </p>
					<p>(v) violation of applicable laws or regulations; or</p>
					<p> (vi) your shipment services. Jhaki reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims.</p>
					<p> You agree not to settle any matter without the prior written consent of Jhaki. Jhaki will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
					<strong>2.	Indemnification of Shipper</strong>
					<p>You agree to defend, indemnify and hold Shippers (and their officers, employees, and agents) harmless, including costs and attorneys’ fees, from any and all damages, claims or losses arising out of your performance of this Agreement, to the extent such damages, claims or losses are caused by you or your employees’ or agents’ negligence or intentional conduct.</p>
					<strong>14.	 APPLICABLE LAW AND DISPUTE RESOLUTION</strong>
					<p>This Agreement and any issues arising therefrom or in connection with the services performed hereunder shall be governed and construed in accordance with the Laws of the Federal Republic of Nigeria.</p>
					<p>Any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or validity thereof, that the Parties are unable to resolve by reasonable consultation and discussion, shall be referred to and settled by arbitration in accordance with the Arbitration and Conciliation Act Cap A18, Laws of the Federation of Nigeria, 2004.</p>
					<p>There shall be one arbitrator. If, within thirty (30) days of notice of the arbitration, the   Parties cannot mutually agree upon the arbitrator, either party may apply to the Chief Judge of the High Court of Lagos State for the appointment of the arbitrator.
                    Nothing in the above sections 12.2 and 12.3 shall prevent any aggrieved party to institute, at its discretion, any legal action under the laws of the Federal Republic of Nigeria in any Court of competent jurisdiction without any recourse to Arbitration.</p>


                    <h4>TERM OF AGREEMENT</h4>
<p>This Agreement will remain in full force and effect while you use the Service. Jhaki may at any time terminate this Agreement in its sole discretion if</p>
<p> (a) you have breached any provision of this Agreement (or have acted in a manner that clearly shows you do not intend to, or are unable to, comply with this Agreement);</p>
<p> (b) Jhaki is required to do so by law (for example, where the provision of the Service to you is, or becomes, unlawful); </p>
<p>(c) Jhaki has elected to discontinue the Service; or</p>
<p> (d) for your poor performance as a Carrier in Jhaki’s sole discretion. Upon termination of this Agreement, your Account and right to access and use the Service will terminate immediately.</p>
<h4>16.	 GENERAL PROVISIONS</h4>
<strong>1.	Changes to Agreement</strong>
<p>This Agreement is subject to occasional revision by Jhaki. In the event of any material changes made to the Agreement, Jhaki will notify you by electronic mail, or other means of communication, before your next use of the changes on the Service. Any changes to this agreement will be effective upon the earlier of</p>
<p> (a) the date you accept the new terms or </p>
<p>(b) thirty (30) calendar days following your receipt of the notice of the changes.</p>
<p> These changes will be effective immediately for new Users of the Service. </p>
<p>Continued use of the Service following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
<strong>2.	Entire Agreement</strong>
<p>This Agreement constitutes the entire agreement between you and us regarding the use of the Service and your provision of motor carrier transportation services. In no event will any terms in Carrier’s tariff, bill of lading or other documentation apply to this Agreement. Jhaki’s failure to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision.</p>
<p> The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word “including” means including without limitation. If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. This Agreement may be executed in counterparts.</p>
<strong>3.	Assignment</strong>
<p>This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Jhaki’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The terms of this Agreement shall be binding upon assignees.</p>
<strong>4.	Notices</strong>
<p>All notices as required by any of the terms and conditions of this Agreement shall be deemed given with the notices prepared, adequately addressed and deposited in the United States mail, postage prepaid. Notices to Jhaki are adequately addressed as follows:
Jhaki, Innovative Technologies Limited.</p>
<p>42, Nnamdi Azikiwe Street, Nigerian Railway Corporation (NRC) Compound, Ebutte Metta<br/>
Phone: +234-(0)-906-254-7051 <br/>
Email: hello@gojhaki.com<br/></p>

<strong>5.	Publication of Information and Confidentiality</strong>
<p>Jhaki has your permission to identify you as a customer of Jhaki or user of our Services and to reproduce your name and logo on the Site and in any other marketing materials.</p>
<p>If you provide Jhaki any feedback, suggestions, or other information or ideas regarding the Service (“Feedback”), you hereby assign to Jhaki all rights in the Feedback and agree that Jhaki has the right to use such Feedback and related information in any manner it deems appropriate. </p>
<p>Jhaki will treat any Feedback you provide as non-confidential and non-proprietary. You agree that you will not submit to Jhaki any information or ideas that you consider to be confidential or proprietary.</p>
<p>If Jhaki, Shipper or Shipper’s Affiliated entities provide you with confidential information relating in any way to their business operations, you shall keep all information strictly confidential, unless disclosure is required by law or judicial process or such information is publicly known or obtained by you without any breach of any confidentiality agreement.</p>
<strong>6.	Severability</strong>
<p>In the event that the operation of any portion of this Agreement results in a violation of any law, or if any provision herein is determined by a court of competent jurisdiction to be invalid or unenforceable, you agree that such portion or provision shall be severable and that the remaining provisions herein shall continue in full force and effect.</p>
<p> The terms and conditions set forth herein shall survive the termination of this Agreement.</p>
<strong>7.	Disclaimer</strong>
<p>THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE." JHAKI EXPRESSLY DISCLAIMS ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED OR STATUTORY, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON- INFRINGEMENT. JHAKI MAKES NO WARRANTY THAT THE SERVICE:</p>
<p> (A) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS;</p>
<p> (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS;</p>
<p> (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE; OR</p>
<p> (D) RESULT IN ANY REVENUE, PROFITS, OR COST REDUCTION. THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. JHAKI IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS.</p>
<p>YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</p>
<p>JHAKI'S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION SERVICES WITH OTHER USERS, BUT YOU AGREE THAT JHAKI HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO THE SERVICES OR ANY TRANSPORTATION SERVICES COORDINATED OR OFFERED OR PURCHASED BY YOU THROUGH THE SERVICES OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT. YOU ASSUME ALL LIABILITY AND RISK OF USING THE SERVICES AND COORDINATING AND/OR OFFERING TRANSPORTATION SERVICES.</p>
<p>WE RESERVE THE RIGHT, BUT HAVE NO OBLIGATION, TO MONITOR DISPUTES BETWEEN YOU AND OTHER USERS. PLEASE CAREFULLY SELECT THE TYPE OF INFORMATION THAT YOU POST ON THE SITE OR THROUGH THE SERVICES OR RELEASE TO OTHERS. WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER USERS (INCLUDING UNAUTHORIZED USERS, OR “HACKERS”). JHAKI ONLY OFFERS TECHNOLOGY THAT ENABLES CARRIERS TO COORDINATE TRANSPORTATION SERVICES. </p>
<p> JHAKI IS NOT A CARRIER. WE ARE NOT INVOLVED IN THE ACTUAL TRANSPORTATION OF CARGO. AS A RESULT, WE HAVE NO CONTROL OVER THE QUALITY OR SAFETY OF ANY VEHICLE, CARGO OR OF THE TRANSPORTATION THAT OCCURS AS A RESULT OF THIS SERVICE; NOR DO WE HAVE ANY CONTROL OVER THE TRUTH OR ACCURACY OF USERS' INFORMATION LISTED ON JHAKI. WE RESERVE THE RIGHT TO CHANGE ANY AND ALL CONTENT, SOFTWARE AND OTHER ITEMS USED OR CONTAINED IN THE SERVICES AT ANY TIME WITHOUT NOTICE.</p>
<p>THE SERVICE MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME FOR MAINTENANCE OR OTHER REASONS. JHAKI ASSUMES NO RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, USER COMMUNICATIONS. JHAKI IS NOT RESPONSIBLE FOR ANY TECHNICAL MALFUNCTION OR OTHER PROBLEMS OF ANY TELEPHONE NETWORK OR SERVICE, COMPUTER SYSTEMS, SERVERS OR PROVIDERS, COMPUTER OR MOBILE PHONE EQUIPMENT, SOFTWARE, FAILURE OF EMAIL OR PLAYERS ON ACCOUNT OF TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET OR AT ANY SITE OR COMBINATION THEREOF, INCLUDING INJURY OR DAMAGE TO A USER'S OR TO ANY OTHER PERSON'S COMPUTER, MOBILE PHONE, OR OTHER HARDWARE OR SOFTWARE, RELATED TO OR RESULTING FROM USING OR DOWNLOADING MATERIALS IN CONNECTION WITH THE WEB AND/OR IN CONNECTION WITH THE SERVICES.</p>
<strong>8.	Limitation of Liability</strong>
<p>JHAKI SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT JHAKI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
<p>JHAKI SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY OTHER USER OR ANY THIRD PARTY. JHAKI SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND JHAKI'S REASONABLE CONTROL. IN NO EVENT SHALL JHAKI'S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES AND TRANSPORTATION SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIVE HUNDRED (US $500.00) U.S. DOLLARS.</p>
<p>THE ASSUMPTION OF RISK AND LIMITATION OF LIABILITY SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN JHAKI AND YOU.</p>
<strong>9.	Headings</strong>
<p>Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.
If you have any questions about this Agreement, please contact Jhaki at hello@gojhaki.com</p>



			</div>
			<div class="col-sm-1">

			</div>
		</div>
	</div>
</section>
            <Footer />
            </div >
        );
    }
}
