import axios from 'axios';


export default class Service{
    static BASE_URL = 'https://api.gojhaki.com';

    static login(payload){
        return axios.post(`${this.BASE_URL}/user/jhaki/login`, payload)
    }

    static verifyOtp(payload){
        return axios.put(`${this.BASE_URL}/user/otp`, payload)
    }

    static registerUser(payload){
        return axios.post(`${this.BASE_URL}/user/register`, payload);
    }

    static forgotPassword(payload){
        return axios.post(`${this.BASE_URL}/user/password/reset`, payload);
    }

    static verifyPasswordToken(payload){
        return axios.post(`${this.BASE_URL}/user/password/token`, payload);
    }

    static resetPassword(payload){
        return axios.post(`${this.BASE_URL}/user/password/change`, payload);
    }

    static getStates(){
        return axios.get(`${this.BASE_URL}/booking/states/cities`);
    }

    static getEstimate(payload){
        return axios.post(`${this.BASE_URL}/booking/estimate/get`, payload)
    }

    static getLandingStats(){
        return axios.get(`${this.BASE_URL}/chart/jhaki`);
    }
}
