import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import jhaki_logo from '../assets/images/logo2.png';

export default class Navbar extends Component{
    render(){
        return (
            <nav className="navbar navbar-default navbar-fixed-top" role='navigation'>
                <div className="container-fluid  ">
                
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar one"></span>
                            <span className="icon-bar two"></span>
                            <span className="icon-bar three"></span>
                        </button>
                        <Link to="/" className="navbar-brand">
                            <img src={jhaki_logo} className="img-responsive" width="150" style={{marginTop: '-15px'}} alt='jhaki_logo' />
                        </Link>

                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to="/carrier">Carrier</Link>
                            </li>
                            <li>
                                <Link to="/shipper">Shipper</Link>
                            </li>
                            <li>
                                <Link to="/driver">Become a Driver</Link>
                            </li>
                            <li>
                                <Link to="/estimate">Estimate</Link>
                            </li>
                            <li>
                                <Link to="/login">Login</Link>
                            </li>
                            <li>
                                <Link to="/signup">Sign Up</Link>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.app.user.jhaki"
                                    className="btn btn-primary btn-class"
                                    style={{backgroundColor: '#21ad21',borderRadius: 0,border: 'none',color: 'white !important'}}>Download
                                    App</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        );
    }
}