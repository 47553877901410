import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default class PrivacyPolicy extends Component{
    render() {
        return (
            <div>
                <Navbar />

                <div className="faq-content-privacy">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                                <h2>Privacy Policy</h2>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                </div>
                <section className="faq-section-privacy">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-1">

                            </div>
                            <div className="col-sm-10">
                                <h3>Document effective 1 April, 2019</h3>
                                <p>Jhaki provides an online and mobile platform to connect Shippers with Carriers for the transportation of cargo. Shippers can submit a request for the transportation of cargo (“Shipment”), Carriers can accept such requests and assign Drivers to fulfil them, and Shippers, Carriers, and Drivers can track such requests. This Privacy Policy describes how Jhaki (“we” or “us”) collects, uses, and discloses information when you use our websites, mobile applications, and other online products and services (collectively, the “Services”) or when you otherwise interact with us.</p>
                                <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>
                                <br/>

                                <h3>Collection of Information</h3>
                                <h4>Information You Provide to Us</h4>
                                <p>We collect information you provide directly to us. For example, we collect information when you fill out a form, request to be a Carrier or a Shipper, log into your Jhaki account, submit or accept a Shipment request, request customer support, or otherwise communicate with us. This information may include name, company name, email address, phone number, payment information, insurance information, service ratings and reviews, and any other information you choose to provide.</p>

                                <h4>Information We Collect When You Use the Services</h4>
                                <p>When you use the Services, we collect additional information about Shipment requests and how they are fulfilled, including the following:</p>

                                <h4>Shippers:</h4>
                                <p>If you are a Shipper and submit a Shipment request, we collect certain details related to your submission, including the date and time of your request, origin and destination addresses, description of the cargo being transported, rate, and other information about the Shipment.</p>
                                <h4>Carriers:</h4>
                                <p> If you are a Carrier, we collect information on your bids on Shipment requests as well as information about your acceptance and fulfillment of any Shipment requests, including vehicle and driver information.
                                Drivers: If you are a driver transporting cargo on behalf of a Carrier, we may collect information on you and your travel to fulfil a Shipment request. We also collect information about your location and about files you upload as described below.</p>

                                <h4>Location Information</h4>
                                <p>Providing Services to our users requires the collection of precise location information. When you download and use our mobile applications, we will ask for permission to access your precise location. If you grant our mobile applications permission to access your location through the permission system used by your mobile operating system, we will collect the precise location of your device. We use this information to track Shipments, and also to understand your typical driving patterns to offer Carriers jobs that are more likely to be relevant to them and their Drivers. We may also derive your approximate location from your IP address or phone number. We may also receive information about your location from third parties, such as the Shippers and Carriers involved in fulfilling any Shipment request.</p>

                                <h4>Photos</h4>
                                <p>Our Services may ask you to photograph and upload to the Services certain documents related to the fulfillment of a Shipment request, such as certificates of insurance and other legal documents, bills of lading, and proofs of delivery. You may also be able to upload pictures of cargo that you carry. To do this, our mobile applications will request access to the camera on your device via the permission system used by your mobile operating system.</p>
                                <h4>Information We Collect Automatically When You Use the Services</h4>
                                <p>When you access or use our Services, we automatically collect information about you, including:</p>
                                <h4>Log Information:</h4>
                                <p> We collect log information about your use of the Services, including the type of browser or app version you use, access times, pages viewed, your IP address, and the page you visited before navigating to our Services.</p>
                                <h4>Device Information:</h4>
                                <p> We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, device and app identifiers, and mobile network information.</p>
                            <h4>Information Collected by Cookies and Other Tracking Technologies:</h4>
                            <p> We use various technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies, and how to disable them, please see “Your Choices” below.</p>
                                <br/>
                            <h3>Use of Information</h3>
                            <h4>Information We Collect from Other Sources</h4>
                            <p>We may also obtain information from other sources and combine that with information we collect directly. These third parties include, but are not limited to, verification services (for instance, to confirm your insurance or regulatory compliance status), credit bureaus, and publicly available sources.</p>
                            <p>We use the information we collect in order to provide and improve our Services, including to connect Shippers with Carriers, match Drivers with Shipment requests, facilitate transportation of cargo, process payments, and confirm delivery. We use your phone number to call you and send you SMS messages about potential and current Shipments. We may also use information about you for other purposes, including to:</p>

                                <p><span style={{fontSize: '20px'}}>•</span>	Send you technical notices, updates, security alerts and support, and administrative messages;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Respond to your comments, questions, and requests and provide customer service;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Communicate with you about products, services, offers, opportunities, promotions, rewards, and events offered by Jhaki and others, and provide news and information we think will be of interest to you;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Monitor and analyze trends, usage, and activities in connection with our Services;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights and property of Jhaki and others;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Personalize and improve the Services and provide advertisements, content, or features that match user profiles or interests;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Link or combine with information we get from others to help understand your needs and provide you with better service; and</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	Carry out any other purpose described to you at the time the information was collected.</p>
                                <br/>
                                <h3>Sharing of Information</h3>
                                <p>We share information we collect about you as described in this Privacy Policy or at the time of collection or sharing, including sharing with other users of our Services and various third-parties as described below.</p>
                                <h4>Sharing with Other Users of the Services</h4>
                                <p>Depending on how you use our Services, we may share information about you with other users of the Services. For example, if you are a driver, we share your name, vehicle information, precise or approximate location, and certain contact information (including phone number) with Shippers involved in the Shipment request you helped to fulfil. If you are a Shipper, your name or company name and your instructions, address, and contact information will be shared with drivers who will transport your cargo. Certain documents and information that you submit to our Services in connection with a Shipment request, such as feedback, may be shared with other users. If you submit driver ratings and reviews, this information may be shared with other users of our Services.</p>

                                <p>We may also share information about you with other third parties, such as:</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;</p>
                                <p><span style={{fontSize: '20px'}}>•</span>	In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation or legal process;</p>
                            <p><span style={{fontSize: '20px'}}>•</span>	If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Jhaki or others;</p>
                            <p><span style={{fontSize: '20px'}}>•</span>	In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;</p>
                            <p><span style={{fontSize: '20px'}}>•</span>	Between and among Jhaki and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership; and</p>
                            <p><span style={{fontSize: '20px'}}>•</span>	With your consent or at your direction.</p>
                            <p><span style={{fontSize: '20px'}}>•</span>	We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.</p>
                                <br/>
                                <h3>Advertising and Analytics Services Provided by Others</h3>
                                <p>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
                                We may allow others to provide analytics services and serve advertisements on our behalf across the Internet and in applications. These entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of the Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information.</p>
                                <p> This information may be used by Jhaki and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity.</p>
                                <p> For more information about interest-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit www.aboutads.info/choices. Your device may also include a feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” on Android) that allows you to opt out of having certain information collected through apps used for behavioural advertising purposes.</p>
                                <br/>
                                <h3>Security</h3>
                                <p>Jhaki takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.</p>
                                <br/>
                                <h3>Contact Us</h3>
                                If you have any questions about this Privacy Policy, please contact us at: <a rel="noopener noreferrer" href="mailto:hello@gojhaki.com" target="_blank">Support Jhaki</a>






                            </div>
                            <div className="col-sm-1">

                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        );
    }
}
