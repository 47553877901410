import React, { Component } from 'react';
import  $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';

import Service from '../Service';
import '../assets/css/otp_page.css';
import jhaki_logo from '../assets/images/logo.png';

export default class Otp extends Component{
    constructor(props){
        super(props);

        this.state = {
            user: {},
            otp: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        if(!this.props.location || !this.props.location.state || !this.props.location.state.user){
            this.props.history.push('/login');
            return;
        }
        let user = JSON.parse(this.props.location.state.user);
        this.setState({
            user: user
        })

    }

    async handleSubmit(e){
        e.preventDefault()
        const URLs = [
            'https://shipper.gojhaki.com',
            'https://driver.gojhaki.com',
            'https://carrier.gojhaki.com',
        ];
        if(this.state.otp.length === 5){
            try{
                let {data: response}= await Service.verifyOtp({
                    contact_email: this.state.user.contact_email,
                    phone: this.state.user.contact_phone,
                    otp: this.state.otp.join("")
                })

                if(response.data){
                    let { authorization, role} = response.data;
                    notify.show('Verified!','success', 5000);
                    window.location.replace(`${URLs[role == 0 || role == 1 ? role : 2]}?token=${authorization}`)
                }
                else{
                    notify.show('Wrong OTP entered!','error', 5000);
                }

            }
            catch(err){
                notify.show('An error occured! Please try again', 'error', 5000);
            }
        }
        else{
            notify.show('Please enter your 5 digits OTP','warning', 3000);
        }
    }
    onOtpChange(index,e){
        if (e.target.value.length > e.target.maxLength) e.target= e.target.value.slice(0, e.target.maxLength);
        if (e.target.value.length == e.target.maxLength) {
            var $next = $(e.target).next('.inputs');
            if ($next.length)
                $(e.target).next('.inputs').focus();
            else
                $(e.target).blur();
        }
        let otp = this.state.otp.slice();

        otp[index] = e.target.value;

        this.setState({
            otp: otp
        });
    }
    render(){
        return (
            <div className=" myposition" style={{padding:"0 60px "}}>
                 <Notifications />
                <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6 text-center">
                    <p><img src={jhaki_logo} alt="jhaki" className="img-responsive" width="150"
                            style={{margin: '0 auto', display: 'block'}}/>
                    </p>
                    <h1>Check your Phone!</h1>
                    <p className="desc">We’ve sent a 5-digit confirmation code to <strong>{this.state.user.contact_email}</strong> & {this.state.user.contact_phone}.
                    <br/>Enter it below to confirm your email address and mobile number.
                    </p>
                    <br/>
                    <h4>Your OTP confirmation code</h4>
                    <form className="form" onSubmit={this.handleSubmit}>

                    <div className="confirmation_code split_input large_bottom_margin" data-multi-input-code="true">
                        <div className="confirmation_code_group">
                        <input className="inputs" value={this.state.otp[0] || ''} onChange={this.onOtpChange.bind(this, 0)} type="number" maxLength="1"
                                 required/>
                        <input className="inputs" value={this.state.otp[1] || ''} onChange={this.onOtpChange.bind(this, 1)} type="number" maxLength="1"
                                 required/>
                        <input className="inputs" value={this.state.otp[2] || ''} onChange={this.onOtpChange.bind(this, 2)} type="number" maxLength="1"
                                 required/>
                        <input className="inputs" value={this.state.otp[3] || ''} onChange={this.onOtpChange.bind(this, 3)} type="number" maxLength="1"
                                 required/>
                        <input className="inputs" value={this.state.otp[4] || ''} onChange={this.onOtpChange.bind(this, 4)} type="number" maxLength="1"
                                 required/>
                        </div>

                    </div>
                    <button type="submit" className="btn btn-success" name="verify">Verify OTP</button>
                    </form>
                    <br/>
                    <div id="div1" style={{visibility: 'hidden'}}>
                    <p className="text-center"><button  style={{color: '#000'}}><i className="fas fa-redo"></i> Send Code
                        Again</button>
                    </p>
                    </div>
                </div>
                <div className="col-sm-3"></div>
                </div>
                <p style={{bottom: 0, position: 'fixed'}} className="hidden-sm hidden-xs">Copyright © {new Date().getFullYear()} Jhaki Technologies. All
                rights
                reserved.</p>
            </div>
        );
    }
}
