import React, { Component } from 'react';
import truckIcon from "../assets/images/truck.svg";
import Notification, { notify } from 'react-notify-toast';
import { Marker,withScriptjs, withGoogleMap, DirectionsRenderer, GoogleMap } from "react-google-maps";
import { compose, withProps } from "recompose"

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Service from '../Service';

import '../assets/css/estimate_page.css';
import map_styles from '../map_styles';


/** getDirections function
 * @param {string} pickup 
 * @param {string} destination 
 * @returns {Object}
 */
async function getDirections(pickup, destination){
    return new Promise((resolve)=>{
        const DirectionsService = new window.google.maps.DirectionsService();
       
        DirectionsService.route(
            {
              origin: pickup,
              destination: destination,
              travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
              resolve({ status, result});
            }
        )
    })
}

const MyMapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBzMTD-nKk8BUt0fSuwc94-xLiIvE8ZoPk&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={5}
      defaultCenter={{ lat: 6.4446, lng: 3.3641}}
      defaultOptions={{
        styles: map_styles()
      }}
    >
        {
            props.pickup_city_details.lat 
            && 
            <Marker 
                icon={truckIcon} 
                position={{ lat: parseFloat(props.pickup_city_details.lat), lng: parseFloat(props.pickup_city_details.lon) }}  
            />
        }
        {
            props.destination_city_details.lat 
            && 
            <Marker 
                position={{ lat: parseFloat(props.destination_city_details.lat), lng: parseFloat(props.destination_city_details.lon) }}  
            />
        }
        {props.directions && (
          <DirectionsRenderer
            directions={props.directions}
            options={{
              polylineOptions: {
                strokeColor: '#fff',
                strokeOpacity: 0.4,
                strokeWeight: 4
              },
              preserveViewport: true,
              suppressMarkers: true,
              icon: { scale: 3 }
            }}
          />
        )}
    </GoogleMap>
  );

export default class Estimate extends Component{
    constructor(props){
        super(props);

        this.state = {
            states: [],
            loading: false,
            pickup_state_details: {},
            destination_state_details: {},
            pickup_cities: [],
            pickup_city_details: {},
            destination_cities: [],
            destination_city_details: {},
            estimate_price:false,
            no_of_trucks: "",
            size: "",
            type: 0,
            cargo_type: 0,
            directions: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    async componentDidMount(){
        let {data: response} = await Service.getStates();
        if(response.data){
            this.setState({
                states: response.data
            })
        }
    }
    async handleChange(e){
        let location = e.target.name.indexOf('pickup_state') > -1 ? 'pickup' : e.target.name.indexOf('destination_state') > -1 ? 'destination' : null;
        
        if(location){
            let [ cities ] = this.state.states.filter((state)=>{
                return state.id == e.target.value;
            })
            this.setState({
                [`${location}_cities`]: cities.cities,
                [`${location}_state_details`]: cities
            })
        }

        let city = e.target.name.indexOf('pickup_city') > -1 ? 'pickup' : e.target.name.indexOf('destination_city') > -1 ? 'destination' : null;
        if(city){
            let [ cities ] = this.state[`${city}_cities`].filter((_city)=>{
                return _city.id == e.target.value;
            })
            this.setState({
                [`${city}_city_details`]: cities
            })
            
        }
      
        await this.setState({
            [e.target.name] : e.target.value
        })
        if(this.state.pickup_city_details.lat && this.state.destination_city_details.lat){
           
            let pickup = {lat: parseFloat(this.state.pickup_city_details.lat), lng: parseFloat(this.state.pickup_city_details.lng)};
            let destination = {lat: parseFloat(this.state.destination_city_details.lat), lng: parseFloat(this.state.destination_city_details.lng)}

            let { result, status } = await getDirections(pickup, destination);
            console.log(result)
            console.log(status)
            if(status === window.google.maps.DirectionsStatus.OK){
                this.setState({
                    directions: result
                })
            }
            else{
                this.setState({
                    directions: null
                })
            }
        }
    }

    async handleSubmit(e){
        e.preventDefault()
        let _response;
        try{
            if(this.state.destination_city_details.id 
                && this.state.pickup_city_details.id
                && this.state.no_of_trucks
                && this.state.size
                && this.state.type && this.state.cargo_type
            ){
                let payload = {
                    destination_id: this.state.destination_city_details.id,
                    origin_id: this.state.pickup_city_details.id,
                    no_of_trucks: this.state.no_of_trucks,
                    size: this.state.size,
                    type: (this.state.type - 1).toString(),
                    cargo_type: (this.state.cargo_type - 1).toString()
                }
                await this.setState({
                    loading: true
                })
                let {data:response} = await Service.getEstimate(payload);
             
                _response = response;
                await this.setState({
                    loading: false
                })
                if(response.data){
                    let percentage = response.data.price * 0.25;
                    
                    let _price = response.data.price - percentage;
                    let price = response.data.price + percentage;
                    this.setState({
                        estimated_price : `NGN${_price.toLocaleString()} - NGN${price.toLocaleString()}`
                    })
                }
                else{
                    notify.show(`${response.message}`, 'error', 5000);
                }
                
            }
            else{
                notify.show('All fields are required!','warning', 3000)
            }
        }
        catch(err){
            await this.setState({
                loading: false
            })
            notify.show(_response.message,'warning', 3000)
        }
    }


    render(){
        
        
        const truck_sizes = [
            {
                type: 1,
                value: '30',
                name: '30 Ton Flat Bed'
            },
            {
                type: 1,
                value: '30',
                name: '30 Ton Sided'
            },
            {
                type: 1,
                value: '40',
                name: '40 Ton Flat Bed'
            },
            {
                type: 1,
                value: '40',
                name: '40 Ton Sided'
            },
            {
                type: 2,
                value: '20',
                name: '20 Ft.'
            },
            {
                type: 2,
                value: '40',
                name: '40 Ft.'
            }
        ]
    
        return (
            <div>
                
                <Navbar />
                <div className="estimate mb-5">
                    <Notification />
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="text-center">Jhaki Price Estimator</h1>
                                <br/>
                            </div>
                            <div className="col-md-6">
                            <form>
                                <div className="form-group">
                                    <div className="col-md-6">
                                        
                                        <label style={{float: 'left', padding: '0px'}}>Import or Export</label>
                                        <select className="form-control" name='type' onChange={this.handleChange} value={this.state.type}>
                                            <option value=''>Select...</option>
                                            <option value='1'>Import</option>
                                            <option value='2'>Export</option>
                                        </select>
                                        
                                    </div>

        
                                    <div className="col-md-6">
                                        <label style={{float: 'left', padding: '0px'}}>Cargo Type</label>
                                        <select className="form-control" onChange={this.handleChange} value={this.state.cargo_type} name='cargo_type'>
                                            <option value=''>Select Cargo Type</option>
                                            <option value='1'>General Cargo</option>
                                            <option value='2'>Containerized Cargo</option>
                                        </select>
                                        
                                    </div>
                                </div>
        
                                <div className="form-group">
                                    <label>Pickup Location:</label><br/>
                                    <div className="col-md-6">
                                        <select className="form-control" onChange={this.handleChange} value={this.state.pickup_state_details.id} name='pickup_state'>
                                            <option value=''>Select pickup state</option>
                                            {
                                                this.state.states.map((state)=>{
                                                    if(this.state.type == 1 && state.status == 1){
                                                        return (<option 
                                                            key={state.id}
                                                            value={state.id}
                                                        > 
                                                        {state.state}
                                                        </option>)
                                                    }
                                                    if(this.state.type == 2){
                                                        return (<option 
                                                            key={state.id}
                                                            value={state.id}
                                                        > 
                                                        {state.state}
                                                        </option>)
                                                    }
                                                })
                                            }
                                        </select>
                                       
                                    </div>
                                    <div className="col-md-6">
                                        <select className="form-control" onChange={this.handleChange} value={this.state.pickup_city_details.id} name='pickup_city'>
                                            <option value=''>Select Pickup City</option>
                                            {
                                                this.state.pickup_cities.map((city)=>{
                                                    if(this.state.type == 1 && city.status == 1){
                                                        return (<option 
                                                            key={city.id}
                                                            value={city.id}
                                                        > 
                                                        {city.cities}
                                                        </option>)
                                                    }
                                                    if(this.state.type == 2){
                                                        return (<option 
                                                            key={city.id}
                                                            value={city.id}
                                                        > 
                                                        {city.cities}
                                                        </option>)
                                                    }
                                                })
                                                
                                            }
                                        </select>
                                        
                                    </div>
                                </div>
        
                                <div className="form-group">
                                    <label>Destination Location:</label><br/>
                                    <div className="col-md-6">
                                        <select className="form-control" onChange={this.handleChange} value={this.state.destination_state_details.id} name='destination_state'>
                                            <option value=''>Select destination state</option>
                                            {
                                                this.state.states.map((state)=>{
                                                    if(this.state.type == 2 && state.status == 1){
                                                        return (<option 
                                                            key={state.id}
                                                            value={state.id}
                                                        > 
                                                        {state.state}
                                                        </option>)
                                                    }
                                                    if(this.state.type == 1){
                                                        return (<option 
                                                            key={state.id}
                                                            value={state.id}
                                                        > 
                                                        {state.state}
                                                        </option>)
                                                    }
                                                })
                                            }
                                        </select>
                                        
                                    </div>
                                    <div className="col-md-6">
                                        <select className="form-control" onChange={this.handleChange} value={this.state.destination_city_details.id} name='destination_city'>
                                            <option value=''>Select Destination City</option>
                                            {
                                                this.state.destination_cities.map((city)=>{
                                                    if(this.state.type == 2 && city.status == 1){
                                                        return (<option 
                                                            key={city.id}
                                                            value={city.id}
                                                        > 
                                                        {city.cities}
                                                        </option>)
                                                    }
                                                    if(this.state.type == 1){
                                                        return (<option 
                                                            key={city.id}
                                                            value={city.id}
                                                        > 
                                                        {city.cities}
                                                        </option>)
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
        
                                <div className="form-group">
                                    <div className="col-md-6">
                                        <label style={{float: 'left', padding: '0px'}}>Truck Type:</label>
                                        <select className="form-control" onChange={this.handleChange} value={this.state.size} name="size">
                                            <option value='0'>Select Truck Type</option>
                                            {
                                                truck_sizes.map((truck,index)=>{
                                                    if(this.state.cargo_type == 1 && truck.type == 1){
                                                        return(
                                                            <option
                                                                value={truck.value} key={index}
                                                            >
                                                                {truck.name}
                                                            </option>
                                                        )
                                                    }
                                                    if(this.state.cargo_type == 2 && truck.type == 2){
                                                        return(
                                                            <option
                                                                value={truck.value} key={index}
                                                            >
                                                                {truck.name}
                                                            </option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                       
                                    </div>
                                    <div className="col-md-6">
                                        <label style={{float: 'left', padding: '0px'}}>Number of Trucks:</label>
                                        <input type="number" onChange={this.handleChange} value={this.state.no_of_trucks} className="form-control" name="no_of_trucks" />
                                       
                                    </div>
                                </div>
        
                                <div className="form-group price_btn">
                                    <div className="col-md-12">
                                        <button type="button" onClick={this.handleSubmit} className="btn btn-success" disabled={this.state.loading}
                                                style={{width: '100%'}}>Get Price Estimate  {this.state.loading && <i className="fa fa-spin fa-spinner"></i>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            </div>
                            <div className="col-md-6">
                                <div className="map-info mb-5" id="estimate">
                                    <div className="text-center">
                                        {
                                            this.state.estimated_price &&
                                        <h5>Estimated Price</h5>
                                        }
                                        <h2>{this.state.estimated_price}</h2>
                                    </div>
                                </div>
                                
                                <MyMapComponent 
                                    id="map"
                                    pickup_city_details={this.state.pickup_city_details}
                                    destination_city_details={this.state.destination_city_details}
                                    directions={this.state.directions}
                                />
                                {
                                    this.state.estimated_price &&
                                        <div className="row">
                                            <div className="col-md-6" id="pickup">
                                            {this.state.pickup_city_details.cities}, {this.state.pickup_state_details.state}
                                            </div>
                                            <div className="col-md-6" id="destination">
                
                                            {this.state.destination_city_details.cities}, {this.state.destination_state_details.state}
                                            </div>
                                        </div>    
                                }
                                
            
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
