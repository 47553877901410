import React, { Component } from 'react';
import '../assets/css/forgot_password.css';
import forgot_password_image from '../assets/images/forgot_password.png';
import jhaki_logo from '../assets/images/logo2.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Service from '../Service';
import Notifications, { notify } from 'react-notify-toast';


export default class ResetPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            token: null,
            password_reset: false
        }
    }
    componentDidMount(){
        if(this.props.match.params.token){
            this.setState({
                token: this.props.match.params.token
            })
           
        }
        else{
            this.history.push('/')
        }
    }
    render(){
        const schema_object = {
            password: Yup.string().required('Password is required'),
            confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm password field is required')
        }
        
        const resetPasswordSchema = Yup.object().shape(schema_object);

        return (
            <div className="forgotten_pass" style={{height: '100vh'}}>
                <div className="" style={{padding:"0 60px "}}>
                    <div className="row forgotten">
                        <div className="col-md-7">
                            <img src={forgot_password_image} className="img-responsive" />
                        </div>
                        <div className="col-md-5 push-down">
                            <img src={jhaki_logo} className="img-responsive" width="100" />
                            
                            
                            <h1>Reset Password</h1>
                            <Formik
                                        initialValues={{
                                            password: '',
                                            confirm_password: ''
                                        }}
                                        validationSchema = {resetPasswordSchema}
                                        onSubmit={async (values, { setSubmitting }) => {
                                            try{
                                                //take user email
                                                let payload = {
                                                    token: this.state.token,
                                                    password: values.password
                                                }
                                                let response = await Service.resetPassword(payload);
                                                this.setState({
                                                    password_reset: true
                                                })
                                            }
                                            catch(err){
                                                this.setState({
                                                    password_reset: true
                                                })
                                            }
                                        }}
                                    >
                                    {({ isSubmitting }) => (
                                        <div>
                                            {
                                                !this.state.token &&
                                                <div className="text-center">
                                                    Please wait...
                                                </div>
                                                
                                            }
                                            {
                                                this.state.password_reset &&
                                                <div className="text-center alert alert-success">
                                                    Password Changed Successfully!
                                                </div>
                                            }
                                            {this.state.token && !this.state.password_reset &&
                                                <Form className="form">
                                                    <div className="form-group has-feedback">
                                                        <label>Password</label>
                                                        <Field type="password" className="form-control" name='password' />
                                                        <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                                        <ErrorMessage name="password" component="div" className="text-danger" style={{fontSize: '12px'}} />
                                                    </div>
                                                    <div className="form-group has-feedback">
                                                        <label>Confirm Password</label>
                                                        <Field type="password" className="form-control" name='confirm_password' />
                                                        <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                                        <ErrorMessage name="confirm_password" component="div" className="text-danger" style={{fontSize: '12px'}} />
                                                    </div>
                            
                                                    <button type="submit" disabled={isSubmitting} className="btn btn-success" name="register" style={{width: '100%'}}>Reset Password</button>
                                                    <br/>
                            
                                                </Form>
                                            }              
                                        </div>
                                    )}
                                    </Formik>
                                    <div className="" style={{paddingTop: '10px'}}>
                                        <p className="text-center"><Link to="/login">Login</Link></p>
                                        <p className="text-center">
                                            <span style={{color: '#fff'}}>Do not have an account?</span> <Link to="/signup">Sign up</Link>
                                        </p>
                                        <p className="text-center">
                                            <Link to="/">Back To Home</Link>
                                        </p>
                                    </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}