import React, { Component } from 'react';
import PageNavbar from '../components/PageNavbar';
import Footer from '../components/Footer';
import SignUpPhone from '../components/SignUpPhone';

import { Link } from 'react-router-dom';
import '../assets/css/driver_page.css';

import earnings_image from '../assets/images/earnings.png';
import clock_image from '../assets/images/clock.png';
import credit_card_image from '../assets/images/credit-card.png';
import shipper_mobile3_image from '../assets/images/shipper_mobile3.png';
import right_arrow_circular_button_image from '../assets/images/right-arrow-circular-button.png';


export default class Driver extends Component {
    render() {
        return (
            <div>
                <PageNavbar />
                <section className="intro hidden-sm hidden-xs hidden-md" style={{ height: '100vh' }}>

                    <div className="col-lg-6 col-sm-12 home-left-driver">

                    </div>
                    <div className="col-lg-6 col-sm-12 home-right">
                        <h1>
                            Drive with gojhaki.com
                        </h1>
                        <p style={{marginRight:"7px"}}>
                            Discover a new world of trucking with Jhaki's on-demand freight
                            matching<br />
                            technology. Get freight trips, extra loads and lifestyle<br />
                            rewards driving with Jhaki!
                        </p>
                        <div
                            className="app"
                            style={{ marginTop: '200px', float: 'right', marginRight: '31px' }}>
                            <Link to="/signup/driver" className="btn btn-success">Sign up as Driver </Link>

                        </div>
                        <button className="scroll-down" address="true"></button>
                    </div>

                </section>
                <div className="mobile-driver hidden-lg">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8 text-center">

                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                </div>
                <div className="quote hidden-lg">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 text-center">
                                <h2 style={{ fontSize: '20px' }}>Drive with goJhaki.com</h2>
                                <p style={{ fontSize: '15px' }}>
                                    Discover a new world of trucking with Jhaki's on-demand freight
                                    matching technology. Get freight trips, extra loads and lifestyle
                                    rewards driving with Jhaki!
                                </p>
                                <div className="col-xs-12">
                                    <div className="app" style={{ marginTop: '30px' }}>
                                        <p className="text-center">
                                            <Link to="/signup/driver" className="btn btn-success">Sign Up as Driver</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </div>
                <section className="products about" style={{ padding: '50px  0' }}>
                    <div className="" style={{padding:"0 60px "}}>
                        <h1 className="text-center" style={{ color: '#21ad21',fontWeight:"600" , marginBottom:"30px"}}>Why choose us</h1>
                        <br />
                        <div className="row">
                            <div className="col-md-4 col-sm-4 text-center whyy" style={{height:"230px"}}>
                                <img
                                    src={earnings_image}
                                    className="img-responsive"
                                    alt="Jhaki"
                                    width="80"
                                    style={{ margin: '0 auto', display: 'block' }}
                                />
                                <h3 style={{fontWeight:"500"}}>Better Earnings</h3>
                                <p style={{fontSize:"15px"}}>
                                    Enjoy better earnings from frequent trips and lifestyle rewards
                                    driving with goJhaki.com
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center whyy" style={{height:"230px"}}>
                                <img
                                    src={clock_image}
                                    className="img-responsive"
                                    alt="jhaki"
                                    width="80"
                                    style={{ margin: '0 auto', display: 'block' }}
                                />
                                <h3 style={{fontWeight:"500"}}>Transparency</h3>
                                <p style={{fontSize:"15px"}}>
                                    Get daily loads for your assigned truck with ease, along with fair and
                                    transparent pricing.
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center whyy" style={{height:"230px"}}>
                                <img
                                    src={credit_card_image}
                                    className="img-responsive"
                                    alt="jhaki"
                                    width="80"
                                    style={{ margin: '0 auto', display: 'block' }}
                                />
                                <h3 style={{fontWeight:"500"}}>Quick Cashout</h3>
                                <p style={{fontSize:"15px"}}>
                                    Instant advance on trips and instant payment settlements for every
                                    single trip.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="how" style={{ backgroundColor: '#fbfbfb' }}>
                    <h2 className="text-center">Introducing Jhaki Driver app</h2>
                    <p style={{ textAlign: 'center',fontSize:"15px" }}>
                        Jhaki Driver app helps you earn smarter with real-time information. Easier
                        to use and more reliable,<br />
                        the app now supports you—like a partner—at every turn.
                    </p>
                    <div
                        id="carousel-how"
                        className="carousel slide"
                        data-ride="carousel"
                        data-interval="false"
                    >
                        <ol className="carousel-indicators">
                            <li data-target="#carousel-how" data-slide-to="0" className="active"></li>
                            <li data-target="#carousel-how" data-slide-to="1"></li>
                            <li data-target="#carousel-how" data-slide-to="2"></li>
                        </ol>

                        <div className="carousel-inner" role="listbox">
                            <div className="item active">
                                <div className="" style={{padding:"0 60px "}}>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4" style={{ marginTop: '20px' }}>
                                            <img
                                                src={shipper_mobile3_image}
                                                className="img-responsive"
                                                alt="jhaki"
                                                width="300"
                                                style={{ display: 'block', margin: '0 auto' }}
                                            />
                                        </div>
                                        <div className="col-sm-4 adjust">
                                            <h2>Get alerts on where to drive when it's busy</h2>

                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="" style={{padding:"0 60px "}}>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4" style={{ marginTop: '20px' }}>
                                            <img
                                                src={shipper_mobile3_image}
                                                alt="jhaki"
                                                className="img-responsive"
                                                width="300"
                                                style={{ display: 'block', margin: '0 auto' }}
                                            />
                                        </div>
                                        <div className="col-sm-4 adjust">
                                            <h2>Know your next move and stay up to date</h2>

                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="" style={{padding:"0 60px "}}>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4" style={{ marginTop: '20px' }}>
                                            <img
                                                src={shipper_mobile3_image}
                                                alt="jhaki"
                                                className="img-responsive"
                                                width="300"
                                                style={{ display: 'block', margin: '0 auto' }}
                                            />
                                        </div>
                                        <div className="col-sm-4 adjust">
                                            <h2>Track your earnings at a glance</h2>
                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                            </div>

                            <a
                                className="right carousel-control"
                                href="#carousel-how"
                                role="button"
                                data-slide="next"
                            >
                                <span aria-hidden="true"
                                ><img
                                        src={right_arrow_circular_button_image}
                                        className="img-responsive"
                                        width="70"
                                        alt=""
                                        style={{ marginTop: '200px' }}
                                    /></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
                <SignUpPhone title="Sign up for free and start driving today." />
                <Footer />
            </div>
        );
    }
}
