import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import jhaki_logo from '../assets/images/logo2.png';
import playstore_logo from '../assets/images/google.png';

export default class Footer extends Component{
    render(){
        return(
            <footer>
                <div className="" style={{ padding: "0 60px" }}>
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                <img src={jhaki_logo} className="img-responsive" width="200" alt="jhaki_logo"/>
                            </p>
                            <hr style={{border: '1px solid #d6d6d6', width: '100%'}}/>
                        </div>

                        <div className="col-sm-4 col-xs-6">
                            <ul>
                                <li>
                                    <Link to="/about">About Jhaki</Link>
                                </li>
                                <li>
                                    <Link to="/carrier">Carrier</Link>
                                </li>
                                <li>
                                    <Link to="/driver">Driver</Link>
                                </li>
                                <li>
                                    <Link to="/shipper">Shipper</Link>
                                </li>
                                <li>
                                    <Link to="/estimate">Estimate</Link>
                                </li>
                                <li>
                                    <Link to="#">Careers</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-4 col-xs-6">
                            <ul>
                                <li>
                                    <Link to="/faqs">FAQs</Link>
                                </li>
                                <li>
                                    <Link to="/">Blog</Link>
                                </li>
                                <li>
                                    <Link to="/terms_and_conditions_shipper">Terms and Conditions For Shippers</Link>
                                </li>
                                <li>
                                    <Link to="/terms_and_conditions_carrier">Terms and Conditions For Carriers</Link>
                                </li>
                                <li>
                                    <Link to="/privacy_policy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <ul className="list-inline">
                                <li>
                                    <a href="https://www.facebook.com/gojhaki/">
                                        <span className="fa fa-facebook-square" style={{fontSize: '2em'}}></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/goJhaki">
                                        <span className="fa fa-twitter" style={{fontSize: '2em'}}></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/gojhaki/about">
                                        <span className="fa fa-linkedin" style={{fontSize: '2em'}}></span>
                                    </a>
                                </li>
                            </ul>
                            <p>hello@gojhaki.com</p>
                            <p>+234-(0)-906-254-7051</p>
                        </div>

                    </div>

                    <div className="col-sm-12" style={{padding: '30px 0'}}>
                        <a href="https://play.google.com/store/apps/details?id=com.app.user.jhaki">
                            <img src={playstore_logo} className="img-responsive" width="180" alt="jhaki_google_download" style={{margin: '0 auto', display: 'block'}} />
                        </a>
                    </div>
                    <hr style={{border: '1px solid #d6d6d6'}}/>
                    <h3>Disclosures</h3>
                    <p style={{fontSize: '12px', fontWeight: 600, textAlign: 'justify'}}>
                        Jhaki is a limited liability
                        company duly registered with the Corporate affairs commission ( CAC) of Nigeria . Jhaki’s sole obligation is
                        to arrange transportation of cargo by a Carrier that is appropriate and authorized to operate by all
                        applicable governmental agencies. As a freight broker, Jhaki does not take possession, custody or control of
                        any cargo. Jhaki does not assume any liability, possessory rights or obligations, and assumes no financial
                        responsibility whatsoever, for cargo, including loss, theft, damage or delayed delivery thereof.</p>

                    <p style={{fontSize: '12px', fontWeight: 600, textAlign: 'justify'}}>
                        Jhaki offers a platform to
                        connect Shippers and Carriers, but does not provide actual transportation services or act in any way as a
                        Carrier. It is the Carrier’s obligation to provide transportation services, which may be scheduled through
                        the use of the Service. Jhaki has no responsibility for any shipping services provided to you as a Shipper
                        by any Carrier.</p>
                    <hr style={{border: '1px solid #d6d6d6'}}/>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <p style={{fontSize: '10px', fontWeight:400, textAlign: 'center'}}>
                            Ground Floor, Virgo Block, Eleganza Plaza, Apapa Road, Apapa
                        </p>
                        <p style={{fontSize: '10px', fontWeight:400, textAlign: 'center'}}>
                            © {new Date().getFullYear()} Jhaki Technologies Limited
                        </p>
                    </div>
                    <div className="col-sm-3"></div>
                </div>
            </footer>
        );
    }
}
