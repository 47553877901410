import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

export default class ShippersTermsAndConditions extends Component{
    render() {
        return (
            <div>
                <Navbar />
                <div className="faq-content">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                                <h2>Terms and Conditions</h2>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                </div>

                <section className="faq-section">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-1">

                            </div>
                            <div className="col-sm-10">
                                <h4 className='text-center'>JHAKI – TERMS OF SERVICE AGREEMENT FOR SHIPPERS</h4>
                                <hr />
                                <p>This Terms of Service Agreement (the “Agreement”) describes the terms by which (“JHAKI INNOVATION LOGISTICS LTD .”) offers to you, as a Shipper, access to its website www.gojhaki.com (the “Site”) and the associated mobile software application platform owned and operated by Jhaki (“Mobile App”). Jhaki provides an online and mobile platform (the “Service”) to connect Shippers with Carriers for the transportation of cargo whereby Shippers can submit a request for the transportation of cargo (“Shipment”).</p>
                                <p> Registered Carriers can accept such requests, and both Shippers and Carriers can track such requests. The Service includes access to all applications, content and downloads offered by Jhaki, including the Site, Mobile App, and associated user content. Jhaki does not assess the suitability, legality, regulatory compliance, quantity, quality or ability of any Shipper or shipped items scheduled through the use of the Service, and Jhaki makes no warranty regarding the same.</p>
                                <p>This Agreement sets forth the terms for use of the Service by Shippers. By signing up and registering with Jhaki or by accessing or using the Service, you are accepting this Agreement, on behalf of yourself or the company, entity or organization that you represent, and you represent and warrant that you have the right, authority, and capacity to enter into this Agreement, on behalf of yourself or the company, entity or organization that you represent.</p>
                                <p> You may not access or use the Service or accept this Agreement if you are not at least 18 years old. Please read this Agreement carefully before using the Service. Use of the Service is conditioned on your agreement to all of the terms and conditions contained in the Agreement, including the policies and terms linked to or otherwise referenced in the Agreement, all of which are hereby incorporated into the Agreement.</p>
                                <p> In the event any separate contract or like document governs or otherwise impacts the parties’ rights, obligations or relationship, this Agreement shall govern to the extent it does not conflict with the terms of any such other contract or like document.
                                </p>

                                <p>Please refer to our <Link to="/privacy_policy">Privacy Policy</Link> for information about how we collect, use and disclose information about users of the Service.</p>

                                <p>PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICE. YOUR ACCESS AND USE OF THE SERVICE CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THESE TERMS, WHICH ESTABLISHES A CONTRACTUAL RELATIONSHIP BETWEEN YOU AND JHAKI. AND IF YOU DO NOT SO AGREE, YOU SHOULD DECLINE THIS AGREEMENT, IN WHICH CASE YOU ARE PROHIBITED FROM ACCESSING OR USING THE SERVICE. JHAKI MAY IMMEDIATELY TERMINATE THESE TERMS OR ANY SERVICE WITH RESPECT TO YOU, OR GENERALLY CEASE OFFERING OR DENY ACCESS TO THE SERVICE OR ANY PORTION THEREOF, AT ANY TIME FOR ANY REASON.</p>

                                <h4>1.DEFINED TERMS</h4>
                                <strong>1.Carrier</strong>
                                <p>Carrier means a truck, barge or rail freight service provider registered with the appropriate regulatory authority including federal and applicable state governments to haul cargo for hire. A Carrier who accesses or uses the Service is an independent contractor and remains solely responsible for screening, selecting, hiring, training, supervising, managing, assigning, and dispatching its drivers; as well as for the inspection and maintenance of its motor vehicle equipment and accessories. A Carrier is solely responsible for its own actions, omissions, training, oversight, compliance with regulatory and safety requirements, and all management of Carrier’s equipment, services, drivers, employees, contractors, agents and servants.</p>
                                <p> A Carrier maintains sole control over the methods and results by which it performs cargo transportation services, and retains the sole duty to provide, maintain, manage and control the equipment, personnel, and expertise required to transport your cargo. Jhaki is not an agent of any Carrier, and no Carrier is an agent of Jhaki.</p>
                                <p>JHAKI IS NOT A CARRIER. NO INTERPRETATION OF WRITTEN OR ORAL REMARKS IN ANY AGREEMENT OR DOCUMENT SHALL BE CONSTRUED TO IMPLY JHAKI AS A CARRIER, OR THAT JHAKI IS SUBJECT TO THE REGULATORY OR LEGAL REQUIREMENTS OR LIABILITIES OF A CARRIER. JHAKI HAS NO RESPONSIBILITY OR LIABILITY FOR ANY TRANSPORTATION OR CARRIER SERVICES PROVIDED TO ANY SHIPPER OR ANY OTHER PARTY USING THE SERVICES.</p>
                                <strong>2.Jhaki</strong>
                                <p>Jhaki is a limited liability company duly registered with the Corporate affairs commission ( CAC) of Nigeria . Jhaki’s sole obligation is to arrange transportation of cargo by a Carrier that is appropriate and authorized to operate by all applicable governmental agencies. As a freight broker, Jhaki does not take possession, custody or control of any cargo nor responsible for ascertaining the quantity and quality of the cargo. Jhaki does not assume any liability, possessory rights or obligations, and assumes no financial responsibility whatsoever, for cargo, including loss, theft, damage or delayed delivery thereof.</p>
                                <p>Jhaki offers a platform to connect Shippers and Carriers, but does not provide actual transportation services or act in any way as a Carrier. It is the Carrier’s obligation to provide transportation services, which may be scheduled through the use of the Service. Jhaki has no responsibility for any shipping services provided to you as a Shipper by any Carrier.</p>
                                <strong>3.Shipper</strong>
                                <p>A shipper is an individual, entity or organisation that owns, possesses, or otherwise have rights to transport cargo it seeks to ship by way of the Service.</p>
                                <strong>4.User</strong>
                                <p>A User is any entity which accesses or uses the Service, and may be a Carrier or a Shipper.</p>
                                <strong>5.User Content</strong>
                                <p>User Content means any and all information, data, textual, audio, and/or visual content, commentary and feedback related to the Service, ratings, reviews and other content that a User submits to, or uses with, the Service. User Content includes the information provided in a Shipment request or acceptance.</p>
                                <p>ii. Jhaki and you are independent contractors, and except to the extent required by law, no agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship is intended or created by this Agreement.</p>

                                <h4>2.DESCRIPTION OF SERVICES</h4>
                                <strong>1.Shipment Requests</strong>
                                <p>Jhaki provides a platform via the Mobile App and Website (“Service”) through which you may submit proposed Shipments. You must provide the following information as part of requesting a Shipment:</p>
                                <p>1.	The Shipper’s name (Individual or Corporate)<br />
                                    2.	The origin and destination addresses for the Shipment;<br />
                                    3.	The requested dates for pickup and drop-off of the Shipment;<br />
                                    4.	A description of the cargo being transported as part of the Shipment (including weight);<br />
                                    5.	The specific equipment requirements; and<br />
                                    6.	Any other information necessary for the safe and proper transportation of the cargo.<br />
                                </p>
                                <p>Once Jhaki accepts a Shipment for posting, the Shipment’s details will be posted to the Service. You may cancel a Shipment at any time prior to a Carrier accepting it and subject to forfeiture of any amount paid in processing the service.</p>
                                <p> A Carrier may view a list of the Shipments available to it at any time and accept a Shipment through the Service. Once a Carrier accepts a Shipment, it will be assigned to perform transportation services related to the Shipment. Upon a Carrier’s acceptance, Jhaki will notify you that the Shipment has been accepted. Jhaki does not guarantee that a Shipment will be accepted by any Carrier.</p>

                                <strong>2.Bills of lading and proofs of delivery</strong>
                                <p>By Jhaki’s Terms & Conditions applicable to Carrier, Carriers whose services are engaged hereunder are instructed to issue bills of lading to their Shippers as required), and to upload copies of those bills of lading to the Service.</p>
                                <p> Carriers are instructed not to name Jhaki as either a shipper or consignee on any bill of lading. Once a Shipment is completed, the Carrier is instructed to post to the Service a proof of delivery signed by the authorized recipient.</p>
                                <p>As a value added service to you, but not as any legal or contractual obligation, Jhaki may provide you and Carriers with trouble shooting, tracking, inquiry, and other services. In order to do so, however, Jhaki must have access to the bill of lading a Carrier issues to you for the subject Shipment.</p>
                                <p> In the event a Carrier fails to post a bill of lading to the Service, we may request that you post it yourself, or otherwise make it available to Jhaki. Jhaki will not be responsible for any consequence arising from your failure to post a bill of lading to the Service.
</p>

                                <strong>3.	Completion of the Shipment</strong>
                                <p>By Jhaki’s Terms & Conditions applicable to Carriers, Jhaki instructs Carriers to upload to the Service a proof of delivery signed by the consignee once Shipments are completed, and send you any questions or concerns regarding the Shipment.</p>

                                <h4>3.YOUR RESPONSIBILITIES</h4>
                                <strong>1.Representations and Warranties</strong><br />
                                <p>You hereby represent and warrant as follows:</p>
                                <p>1.  You will not use the Service to ship contrabands (as defined by any local or International law in force) and hazardous materials;<br />
                                    2.	You own the shipped cargo or have all necessary rights to ship it;<br />
                                    3.	You agree that Jhaki will not be liable for loss, damage, theft, destruction, or delayed delivery of any cargo;<br />
                                    4.	You are solely responsible for obtaining any required insurance to cover any anticipated losses of cargo; and<br />
                                    5.	Your requested pick-up and delivery dates and hours will not require a Carrier to violate hours of service regulations under applicable law.</p>
                                <strong>2.Claims for Loss or Damage</strong>
                                <p>Should you wish Jhaki’s assistance with or other participation in any claim for lost, damaged, delayed or destroyed cargo, you should file with Jhaki its written notice of claim to Carrier. Jhaki may facilitate processing of cargo claims; PROVIDED, however, you understand and agree that, notwithstanding Jhaki’s participation in or assistance with any cargo claim, Jhaki is not liable for any cargo loss which was not proximately caused by Jhaki’s own wrongdoing.</p>

                                <strong>3.Non-Solicitation</strong>
                                <p>During the term of this Agreement, and for a period of one (1) year from its termination, you shall neither initiate nor accept any direct or indirect business relationship with any Carrier to which you are first introduced by the Service, or in connection with use of the Service.</p>
                                <p> You understand that any such Carrier will offer and provide transportation services to it exclusively through the Service unless otherwise agreed by the parties in writing. </p>
                                <p>Your obligation in this regard extends to instances where the Carrier contacts you and seeks to establish a business relationship that does not include Jhaki. This provision shall continue in force beyond termination of this Agreement for one (1) year subsequent to termination.</p>
                                <p>If you book or otherwise make available any direct or indirect business relationship with any Carrier that was first introduced to you by Jhaki in violation of this Agreement, you shall be jointly and severally liable with the Carrier to Jhaki for each such violation in an amount equal to twenty percent (20%) of all revenues invoiced by Carrier to you.
</p>
                                <strong>4.Cargo Description and Disclosures</strong>
                                <p>You shall disclose to Jhaki all information incidental to the performance of Service, reasonably necessary for Jhaki to arrange transportation in compliance with applicable regulatory, legal and industry standards.</p>
                                <p> You shall also be responsible and liable for providing accurate description of cargo including without limitation to commodity type, dimensions and weight, and any special handling requirements.</p>
                                <strong>5.Cargo Securement</strong>
                                <p>You shall bear the maximum responsibility allowed by law to review and accept or decline the Carrier’s trailer for cleanliness, odor, leaks, dirt or other conditions that may be unacceptable to you. If a trailer or any equipment is unacceptable, it is your responsibility to refuse loading of the trailer. In such event, you may notify Jhaki immediately and request alternative arrangements. You should ensure in coordination with Carrier that adequate blocking, bracing and packaging for the safe stowage of cargo is used.</p>
                                <p> You acknowledge that Jhaki will never be in possession of any cargo being transported in connection with use of the Service, and that Jhaki will not be responsible or have any role in the securement of cargo for transportation.</p>
                                <strong>6.Shipping Documents</strong>
                                <p>You shall not insert “Jhaki” or “Jhaki Innovation Logistics Ltd.” on any receipt, bill of lading, manifest, or other shipping document.</p>
                                <p>In the event you do so, such insertion shall be deemed to be for your convenience, or due to your oversight, and shall not operate to alter Jhaki’s status as a Broker, or the Carrier’s status as the responsible Carrier. The provisions set forth in any shipping document used by a Carrier or you shall not supersede, alter, or modify any term of this Agreement or add any liability or responsibility to Jhaki.</p>

                            <h4>4.Carrier Selection</h4>
                            <p>Jhaki establishes and follows written procedures to evaluate Carriers prior to their use by Jhaki. Jhaki continues to evaluate certain Carriers at certain intervals, as set forth by Jhaki’s internal risk management and Carrier selection policies, which may be amended from time to time with or without notice to Carriers or Shippers. Jhaki is not responsible in any way for the acts and/or omissions of Carriers or their drivers.</p>
                            <p>Jhaki provides a means for Shippers and Carriers to rate and review each other and to have those ratings made available to other Users. We do not express any opinion, nor does Jhaki make any assurances regarding, the truth or accuracy of any User reviews or ratings. Jhaki does not regularly monitor or remove reviews or ratings, or any portion thereof, unless they contain Content we deem inappropriate.</p>
                            <h4>5.Payment Terms</h4>
                            <p>For each Shipment, you will pay Jhaki the freight charge quoted to you upon acceptance of the applicable Shipment on the Service (“freight charge”), plus any additional charges you may incur related to the Shipment. Freight charges may include, but are not limited to, detention time which may be charged, by way of example, for time beyond 30 minutes at each location for trucks 26 feet or less in length and 120 minutes at each location for trucks over 26 feet in length, overnight storage due to your not being available or able to receive the Shipment from Carrier, any differences between costs for the actual Shipment and the description of the Shipment created on the Service.</p>
                            <p> Jhaki may change pricing for the Service (from time to time at its sole discretion) by updating the Site and Mobile App and without notice to Users. Registered Carriers are required to provide advance notice of ancillary services for which they intend to charge additional fees, such as lumper charges, specialized equipment needed for blocking, bracing, marking or securement, “truck ordered not used” fees, extra stop charges and driver assist charges.</p>
                            <p> However, advance notice is not always possible or practical, and Jhaki may not be able to receive and communicate to carrier requests for approval of additional charges before the services are rendered. In that event, you agree to pay the costs of any accessorial and/or ancillary services which Jhaki determines are reasonable and necessary.</p>
                            <p>You understand and agree that freight charges quoted to you for a specific transport may be increased or decreased at any time without notice unless and until accepted by your formally booking the transport; and that any accepted or quoted freight charges shall not apply to any future shipments you might seek to book with Jhaki, as the costs of services evolve over time and are subject to changing market conditions. Validity period of quoted price is 12hours from receipt of invoice.</p>
                            <p>Unless otherwise agreed by the parties, payment is due immediately from the date of each booking before it can be confirmed and listed live on the platform.</p>
                            <p>When it receives full payment of a freight charge (including all surcharges and accessorial charges), Jhaki shall display a completed charge screen. This is your electronic receipt, and you should print or save it for your records. You warrant that you have the right to use any credit card(s), cheques, or other payment means provided to Jhaki and used to initiate payment of any freight charge. All information you provide to Jhaki’s third-party payment processor or to Jhaki must be accurate, current and complete.
                                Freight charges paid are non-refundable.</p>


                            <h4>LICENSES</h4>
                            <strong>1.	Accounts</strong>
                            <p>In order to use certain features of the Service, you must register for an account with Jhaki (“Account”) and provide certain information as prompted by the registration and Shipment creation form. You represent and warrant that:</p>
                            <p> (a) all required registration information you submit is truthful and accurate; and</p>
                            <p>(b) you will maintain the accuracy of such information. You may cancel your Account at any time, for any reason, by contacting Jhaki or following the instructions on the Service. You are responsible for maintaining the confidentiality of your Account login credentials and are fully responsible for all activities that occur under your Account.</p>
                            <p> You agree to immediately notify Jhaki of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Jhaki is not liable for any loss or damage arising from your failure to comply with the above requirements.</p>
                            <strong>2.Site and Mobile App</strong>
                            <p>Jhaki owns the Site and all intellectual property therein. Subject to the terms of this Agreement, Jhaki grants you a limited, non-transferable, non-exclusive, revocable license to use the Site for your internal business use during the term of this Agreement. Jhaki owns the Mobile App and all intellectual property therein. Subject to the terms of this Agreement, Jhaki grants you a limited, non-transferable, non-exclusive, revocable license to install and use the Mobile App, in executable object code format only, solely on your own handheld mobile device and for your internal business use during the term of this Agreement.</p>
                            <p>You shall not:</p>
                            <p> (i) remove any copyright, trademark or other proprietary notices from any portion of the Services;</p>
                            <p> (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Jhaki;</p>
                            <p> (iii) decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law;</p>
                            <p> (iv) link to, mirror or frame any portion of the Services;</p>
                            <p> (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or</p>
                            <p> (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks
</p>
                            <strong>4.Restrictions</strong>
                            <p>The rights granted to you in this Agreement are subject to the following restrictions:</p>
                            <p>1.	You shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Service;<br />
                                2.	You shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Service;<br />
                                3.	You shall not access the Service in order to build a similar or competitive service; and<br />
                                4.	Except as expressly stated herein, no part of the Service may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.</p>
                            <strong>5.Limited Support</strong>
                            <p>Users may contact Jhaki’s technical support center for any support-related issues arising from the use of the Service by following the instructions on the Service.</p>


                            <h4> 7.Insurance</h4>
                            <p>Jhaki agrees to maintain, at its own expense, at all times, at least the following insurance coverage amounts:<br />
                                General Liability: NGN10,000,000 <br />
                                Auto Liability: NGN10,000,000 <br />
                                Contingent Cargo Liability: NGN10,000,000<br /></p>
                            <p>Upon request, Jhaki may procure insurance coverage amounts that exceed these limits, and the evidence of such coverage shall be in the form of an insurance certificate provided to you on request. Jhaki’s maximum liability to you or Carrier for any loss shall be limited to Jhaki’s insurance policy terms and conditions and the Naira amounts for coverage herein below.</p>
                            <p>Jhaki’s contingent cargo insurance is subject to the terms, conditions and certain limitations and/or exclusions as contained in the policy, and the terms, conditions and requirements as outlined within this Agreement, which are subject to change at any time.</p>
                            <p>The existence of Jhaki’s contingent cargo insurance in no way shifts or places any legal or contractual liability on Jhaki, nor does it exonerate the Carrier’s duties and liabilities under this Agreement.</p>
                            <h4>8.Ownership</h4>
                            <p>Jhaki owns intellectual property rights in and to the Service, including but not limited to the Site, Mobile App, including all related software and servers, in and to Jhaki’s trademarks, service marks, trade names, logos, domain names, taglines and trade dress (collectively, the “Marks”). </p>
                            <p> Users acknowledge and agree that Jhaki owns all right, title, and interest in and to the Service, including all intellectual property rights therein. Users understand and agree that without a written license agreement with Jhaki. Users may not make any use of the Marks. Except as expressly granted in this Agreement, all rights, title and interest in and to the Service, and in and to the Marks are reserved by Jhaki, Inc.</p>
                            <h4>9.	 MODIFICATION OF THE SERVICE</h4>
                            <p>Jhaki reserves the right, at any time, to modify, suspend, or discontinue the Service or any part thereof with or without notice. You agree that Jhaki will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service or any part thereof.</p>
                            <h4>10.	 LIMITATIONS ON USE OF THE SERVICE</h4>
                            <p>You agree not to use the Service to upload, transmit, display, or distribute any User Content that:</p>
                            <p> (a) violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; or</p>
                            <p> (b) is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature or which is harmful to minors in any way.</p>
                            <p>In addition, you agree not to use the Service to:</p>
                            <p> (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data;</p>
                            <p> (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise;</p>
                            <p> (c) harvest, collect, gather or assemble information or data regarding other Users, including e-mail addresses, without their consent;</p>
                            <p> (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the Service or violate the regulations, policies or procedures of such networks;</p>
                            <p> (e) attempt to gain unauthorized access to the Service, other computer systems or networks connected to or used together with the Service, through password mining or other means;</p>
                            <p> (f) harass or interfere with another User’s use and enjoyment of the Service; or</p>
                            <p> (g) introduce software or automated agents or scripts to the Service so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the Service.</p>
                            <p>Jhaki reserves the right to review any User Content, investigate, and /or take appropriate action against you in its sole discretion, including removing or modifying User Content, terminating your Account, and/or reporting you to law enforcement authorities. However, Jhaki has no obligation, to monitor, modify or remove any User Content.
</p>

                            <h4>11.USER CONTENT</h4>
                            <strong>1.User Content</strong>
                            <p>You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third party personally identifiable. You hereby represent and warrant that your User Content does not violate any provision of this Agreement. For the avoidance of doubt, User Content may include third party content you submit.</p>
                            <p> You agree not to submit third party content unless you have the consent of the applicable third party owner of such content. You may not state or imply that your User Content is in any way provided, sponsored or endorsed by Jhaki. You acknowledge and agree that Jhaki is not responsible for any loss or damage resulting from anyone’s use or reliance on User Content and Jhaki makes no guarantees regarding the accuracy, completeness, usefulness currency, suitability, or quality of any User Content, and assumes no responsibility for any User Content.</p>
                            <strong>2.License</strong>
                            <p>You hereby grant, and represent and warrant that you have the right to grant, to Jhaki an irrevocable, nonexclusive, royalty-free and fully paid, sublicenseable, worldwide license, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels, now known or hereafter devised (including in connection with the Services and Jhaki's business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity. All rights in and to the User Content not expressly granted to Jhaki in this Agreement are reserved by Users.</p>
                            <strong>3.Anonymous Data</strong>
                            <p>Jhaki may create anonymous data records (“Anonymous Data”) from your User Content by using commercially reasonable efforts to exclude any and all information (such as company name) that makes the data identifiable to you. Jhaki may use and disclose Anonymous Data for any purpose, including improving the Service.</p>
                            <strong>4.Disclosure</strong>
                            <p>Jhaki may share your User Content</p>
                            <p> (a) with third party service providers;</p>
                            <p> (b) if another company acquires Jhaki; and/or</p>
                            <p> (c) to comply with relevant laws, to respond to subpoenas or warrants or assist in preventing any violation or potential violation of the law or this Agreement.</p>
                            <strong>5.Copyright Complaints and Copyright Agent</strong>
                            <p>Jhaki respects the intellectual property of others, and expects Users to do the same. If you believe, in good faith, that any materials on the Services infringe upon your copyrights, please send the following information to Jhaki’s Copyright Agent at hello@gojhaki.com;</p>
                            <p>A description of the copyrighted work that you claim has been infringed, including specific location on the Services where the material you claim is infringing is located. Include enough information to allow Jhaki to locate the material, and explain why you think an infringement has taken place;</p>
                            <p>A description of the location where the original or an authorized copy of the copyrighted work exists – for example, the URL (Internet address) where it is posted or the name of the book in which it has been published;</p>
                            <p>Your address, telephone number, and e-mail address;</p>
                            <p>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
                            <p>A statement by you, made under penalty of perjury, that the information in your notice is accurate, and that you are the copyright owner or authorized to act on the copyright owner's behalf; and</p>
                            <p>An electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest.</p>
                            <p>We may terminate access, usage or subscription to the Site, as the case may be, for repeat infringers in appropriate circumstances.</p>
                            <h4>12.	 THIRD PARTY SITES AND LOCATION INFORMATION</h4>
                            <strong>1.Third Party Sites</strong>
                            <p>The Service might contain links to third party websites, services, and advertisements for third parties (collectively, “Third Party Sites”). Such Third Party Sites are not under the control of Jhaki and Jhaki is not responsible for any Third Party Sites.</p>
                            <p> Jhaki does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites. You use all Third Party Sites at your own risk. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third Party Sites.</p>
                            <strong>2.Location Information</strong>
                            <p>Location data provided by the Service is for basic location purposes only and is not intended to be relied upon in situations where precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage, or other loss.</p>
                            <p> Neither Jhaki, nor any of its content providers, guarantees the availability, accuracy, completeness, reliability, or timeliness of location data displayed by the Services, whether provided by Jhaki, third party content providers, or Users.</p>
                            <p>Geolocational data that You upload, provide, or post on the Services may be accessible to certain Users of the Services. You assume any and all risk of providing such data to other Users of the Services.
</p>
                            <h4>13.	INDEMNITY</h4>
                            <strong>1.Indemnification of Jhaki</strong>
                            <p>You agree to defend, indemnify and hold Jhaki (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of your</p>
                            <p> (i) use of the Service,</p>
                            <p> (ii) User Content,</p>
                            <p> (iii) interaction with any other User, </p>
                            <p>(iv) violation of this Agreement;</p>
                            <p> (v) violation of applicable laws or regulations; or</p>
                            <p> (vi) your shipment. Jhaki reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims.</p>
                            <p> You agree not to settle any matter without Jhaki’s prior written consent. Jhaki will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
                            <strong>2.Indemnification of you</strong>
                            <p>By Jhaki’s separate Terms & Conditions applicable to Carriers, your Carrier agrees to defend, indemnify and hold you (and your officers, employees, and agents) harmless, including costs and attorneys’ fees, from any and all damages, claims or losses arising out of its performance of this Agreement, to the extent such damages, claims or losses are caused by the negligence or intentional conduct of the Carrier or its employees or agents.</p>

                            <strong>3.Indemnification of Carrier</strong>
                            <p>You agree to defend, indemnify and hold Carrier (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any and all damages, claims or losses arising out of its performance of this Agreement, to the extent such damages, claims or losses are caused by your or your employee’ or agents’ negligence or other intentional conduct.</p>
                            <h4>14.	APPLICABLE LAW AND DISPUTE RESOLUTION</h4>
                            <p>This Agreement and any issues arising therefrom or in connection with the Terms or the existence, breach, termination, enforcement, interpretation or validity thereof or services performed hereunder shall be governed and construed in accordance with the Laws of the Federal Republic of Nigeria.</p>
                            <p>Any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or validity thereof, that the Parties are unable to resolve by reasonable consultation and discussion, shall be referred to and settled by arbitration in accordance with the Arbitration and Conciliation Act Cap A18, Laws of the Federation of Nigeria, 2004.</p>
                            <p>There shall be one arbitrator. If, within thirty (30) days of notice of the arbitration, the   Parties cannot mutually agree upon the arbitrator, either party may apply to the Chief Judge of the High Court of Lagos State for the appointment of the arbitrator.</p>
                            <p>Nothing in the above sections 12.2 and 12.3 shall prevent any aggrieved party to institute, at its discretion, any legal action under the laws of the Federal Republic of Nigeria in any Court of competent jurisdiction without any recourse to Arbitration.</p>
                            <p>The Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act in force and shall be held in Lagos, Nigeria.</p>

                            <h4>15.TERM OF AGREEMENT</h4>
                            <p>This Agreement will remain in full force and effect while you use the Service. Jhaki may at any time terminate this Agreement in its sole discretion if</p>
                            <p> (a) you have breached any provision of this Agreement (or have acted in a manner that clearly shows you do not intend to, or are unable to, comply with this Agreement);</p>
                            <p> (b) Jhaki is required to do so by law (for example, where the provision of the Service to you is, or becomes, unlawful);</p>
                            <p> (c) Jhaki has elected to discontinue the Service; or</p>
                            <p> (d) for your poor performance as a Shipper in Jhaki’s sole discretion. Upon termination of this Agreement, your Account and right to access and use the Service will terminate immediately.</p>
                            <h4>16.	 GENERAL PROVISIONS</h4>
                            <strong>1.	Changes to Agreement</strong>
                            <p>This Agreement is subject to occasional revision by Jhaki. In the event of any material changes made to the Agreement, Jhaki will notify you by electronic mail, or other means of communication, before your next use of the changes on the Service. Any changes to this agreement will be effective upon the earlier of </p>
                            <p>(a) the date you accept the new terms or</p>
                            <p> (b) thirty (30) calendar days following your receipt of the notice of the changes. These changes will be effective immediately for new Users of the Service.</p>
                            <p> Continued use of the Service following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
                            <strong>2.Entire Agreement</strong>
                            <p>This Agreement constitutes the entire agreement between you and us regarding the use of the Service. Jhaki’s failure to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision.</p>
                            <p> The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word “including” means including without limitation.</p>
                            <p> If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. This Agreement may be executed in counterparts.</p>
                            <strong>3.Assignment</strong>
                            <p>This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Jhaki’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The terms of this Agreement shall be binding upon assignees.</p>
                            <strong>4.Notices</strong>
                            <p>All notices as required by any of the terms and conditions of this Agreement shall be deemed given with the notices prepared, adequately addressed and deposited in the mail, postage prepaid. Notices to Jhaki are adequately addressed as follows:</p>
                            <p>Jhaki, Innovative Technologies Limited . </p>
                            <p>42, Nnamdi Azikiwe Street, Nigerian Railway Corporation (NRC) Compound, Ebutte Metta</p>
                            <p>Phone: +234-(0)-906-254-7051 </p>
                            <p>Email: hello@gojhaki.com</p>
                            <strong>5.	Publication of Information</strong>
                            <p>Jhaki has your permission to identify you as a customer of Jhaki or user of our Services and to reproduce your name and logo on the Site and in any other marketing materials.</p>
                            <p>If you provide Jhaki any feedback, suggestions, or other information or ideas regarding the Service (“Feedback”), you hereby assign to Jhaki all rights in the Feedback and agree that Jhaki has the right to use such Feedback and related information in any manner it deems appropriate.</p>
                            <p> Jhaki will treat any Feedback you provide as non-confidential and non-proprietary. You agree that you will not submit to Jhaki any information or ideas that you consider to be confidential or proprietary.
</p>
                            <strong>6.	Severability</strong>
                            <p>In the event that the operation of any portion of this Agreement results in a violation of any law, or if any provision herein is determined by a court of competent jurisdiction to be invalid or unenforceable, Shipper, Jhaki and Carrier agree that such portion or provision shall be severable and that the remaining provisions herein shall continue in full force and effect. The terms and conditions set forth herein shall survive the termination of this Agreement.</p>
                            <strong>7.Disclaimer</strong>
                            <p>THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE." JHAKI EXPRESSLY DISCLAIMS ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED OR STATUTORY, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON- INFRINGEMENT. JHAKI MAKES NO WARRANTY THAT THE SERVICE:</p>
                            <p> (A) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS;</p>
                            <p> (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; </p>
                            <p>(C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE; OR</p>
                            <p> (D) RESULT IN ANY REVENUE, PROFITS, OR COST REDUCTION. THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. JHAKI IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS.</p>
                            <p>YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</p>
                            <p>JHAKI'S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION SERVICES WITH OTHER USERS, BUT YOU AGREE THAT JHAKI HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO THE SERVICES OR ANY TRANSPORTATION SERVICES COORDINATED OR OFFERED OR PURCHASED BY YOU THROUGH THE SERVICES OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT. YOU ASSUME ALL LIABILITY AND RISK OF USING THE SERVICES AND COORDINATING AND/OR OFFERING TRANSPORTATION SERVICES.</p>
                            <p>WE RESERVE THE RIGHT, BUT HAVE NO OBLIGATION, TO MONITOR DISPUTES BETWEEN YOU AND OTHER USERS. PLEASE CAREFULLY SELECT THE TYPE OF INFORMATION THAT YOU POST ON THE SITE OR THROUGH THE SERVICES OR RELEASE TO OTHERS. WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER USERS (INCLUDING UNAUTHORIZED USERS, OR “HACKERS”).</p>
                            <p> JHAKI ONLY OFFERS TECHNOLOGY THAT ENABLES CARRIERS TO COORDINATE TRANSPORTATION SERVICES. JHAKI IS NOT A CARRIER. WE ARE NOT INVOLVED IN THE ACTUAL TRANSPORTATION OF CARGO. AS A RESULT, WE HAVE NO CONTROL OVER THE QUALITY OR SAFETY OF ANY VEHICLE, CARGO OR OF THE TRANSPORTATION THAT OCCURS AS A RESULT OF THIS SERVICE; NOR DO WE HAVE ANY CONTROL OVER THE TRUTH OR ACCURACY OF USERS' INFORMATION LISTED ON JHAKI. WE RESERVE THE RIGHT TO CHANGE ANY AND ALL CONTENT, SOFTWARE AND OTHER ITEMS USED OR CONTAINED IN THE SERVICES AT ANY TIME WITHOUT NOTICE.</p>
                            <p>THE SERVICE MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME FOR MAINTENANCE OR OTHER REASONS. JHAKI ASSUMES NO RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, USER COMMUNICATIONS. JHAKI IS NOT RESPONSIBLE FOR ANY TECHNICAL MALFUNCTION OR OTHER PROBLEMS OF ANY TELEPHONE NETWORK OR SERVICE, COMPUTER SYSTEMS, SERVERS OR PROVIDERS, COMPUTER OR MOBILE PHONE EQUIPMENT, SOFTWARE, FAILURE OF EMAIL OR PLAYERS ON ACCOUNT OF TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET OR AT ANY SITE OR COMBINATION THEREOF, INCLUDING INJURY OR DAMAGE TO A USER'S OR TO ANY OTHER PERSON'S COMPUTER, MOBILE PHONE, OR OTHER HARDWARE OR SOFTWARE, RELATED TO OR RESULTING FROM USING OR DOWNLOADING MATERIALS IN CONNECTION WITH THE WEB AND/OR IN CONNECTION WITH THE SERVICES.
</p>
                            <strong>8.Limitation of Liability</strong>
                            <p>JHAKI SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT JHAKI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
                            <p>JHAKI SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF:</p>
                            <p> (i) YOUR USE OF OR RELIANCE ON THE SERVICE OR YOUR INABILITY TO ACCESS OR USE THE SERVICE; OR </p>
                            <p>(ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY OTHER USER OR ANY THIRD PARTY. JHAKI SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND JHAKI'S REASONABLE CONTROL.</p>
                            <p> IN NO EVENT SHALL JHAKI'S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICE FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIVE HUNDRED THOUSAND NAIRA (NGN500,000).
                            THE ASSUMPTION OF RISK AND LIMITATION OF LIABILITY SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN JHAKI AND YOU.
</p>
                            <strong>9.Headings</strong>
                            <p>Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.
If you have any questions about this Agreement, please contact Jhaki at hello@gojhaki.com</p>



                        </div>
                        <div className="col-sm-1">

                        </div>
                    </div>
                    </div>
                </section>
            <Footer />
            </div >
        );
    }
}
