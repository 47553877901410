import React, { Component } from 'react';
import './App.css';
import './assets/css/style.css';
import Main from './pages/Main';
import Carrier from './pages/Carrier';
import Shipper from './pages/Shipper';
import Driver from './pages/Driver';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import About from './pages/About';
import FAQs from './pages/FAQs';
import ShippersTermsAndConditions from './pages/ShippersTermsAndConditions';
import CarriersTermsAndConditions from './pages/CarriersTermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Error404 from './pages/Error404';
import SignUpForm from './pages/SignUpForm';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Otp from './pages/Otp';
import Estimate from './pages/Estimate';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

class App extends Component{

  render(){
  
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Main} />
          
          <Route path="/carrier" component={Carrier} />
          <Route path="/shipper" component={Shipper} />
          <Route path="/driver" component={Driver} />
          <Route path="/estimate" component={Estimate} />
          <Route 
            path="/login" 
            render={(props)=> <Login {...props} />}
          />
          <Route 
            path="/signup/:type" 
            render={(props)=> <SignUpForm {...props} />}
          />
          <Route path="/signup" component={SignUp} />
          <Route path="/about" component={About} />
          <Route path="/faqs" component={FAQs} />
          <Route path="/terms_and_conditions_shipper" component={ShippersTermsAndConditions}/>
          <Route path="/terms_and_conditions_carrier" component={CarriersTermsAndConditions}/>
          <Route path="/privacy_policy" component={PrivacyPolicy}/>
          <Route path="/forgot_password" component={ForgotPassword}/>
          <Route path="/password/reset/:token" component={ResetPassword}/>
          <Route 
            path="/otp" 
            render={(props)=><Otp {...props}  />}
          />
           
          <Route component={Error404} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
