import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import signup_phone_image from '../assets/images/signup_phone.png';

export default class SignUpPhone extends Component{
    render(){
        return (
            <div>
                 <div className="cta-desktop hidden-sm hidden-md hidden-xs" style={{height:'400px', backgroundColor: '#000', overflow: 'hidden'}}>
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 style={{color: 'white', textAlign: 'justify', paddingTop:'50px'}}>
                                    <img src={signup_phone_image} style={{margin: '0 auto', display: 'block'}} alt="gojhaki" className="img-responsive" width="500" />
                                </h3>
                            </div>
                            <div className="col-lg-6" style={{paddingTop: '100px'}}>
                                <h2 style={{fontSize: '45px', fontWeight: 900, color: 'white'}}>{this.props.title}</h2>
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cta-mobile hidden-lg" style={{backgroundColor: '#000', textAlign: 'center', padding: '50px 0'}}>
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{fontSize: '30px', fontWeight: 900, color: 'white'}}>{this.props.title}</h2>
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
