import React, { Component } from 'react';
import jhaki_logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Notifications, { notify } from 'react-notify-toast';
import * as Yup from "yup";
import Service from "../Service";


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const signInSchema = Yup.object().shape({
            password: Yup.string().required('Password is Required'),
            email: Yup.string().email('Invalid email').required('Email is Required'),
        });

        const URLs = [
            'https://shipper.gojhaki.com',
            //'https://driver.gojhaki.com',
            'https://carrier.gojhaki.com',
        ];


        return (
            <section className="login-page" style={{ height: '100vh' }}>
                <Notifications />
                <div className="col-lg-5 col-sm-12 login-left">
                    <div className="col-md-8 try">
                        <Link to={"/"}>
                            <img
                                src={jhaki_logo}
                                alt="jhaki"
                                className="img-responsive"
                                width="150"
                                style={{ margin: '0 auto', display: 'block' }}
                            />
                        </Link>

                        <div className="row">
                            <div className="col-lg-12">

                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                email: this.props.location && this.props.location.state && this.props.location.state.contact_email ? this.props.location.state.contact_email : '',
                                password: ''
                            }}
                            validationSchema={signInSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                                let _login;

                                try {
                                    let { data: login, status } = await Service.login(values);
                                    if (login.data) {
                                        notify.show('Login Successful!', 'success', 5000);
                                        let { authorization, status, role, verified } = login.data;
                                        if (verified != 1) {
                                            this.props.history.push(`/otp`, { user: JSON.stringify(login.data) });
                                            return;
                                        }

                                        //window.location.replace(`${URLs[role == 0 || role == 1 ? role : 2]}?token=${authorization}`)
                                        if (role == 0) {
                                            window.location.replace(`${URLs[0]}?token=${authorization}`)
                                        } else {
                                            window.location.replace(`${URLs[1]}/${authorization}`)
                                        }
                                    } else if (status === 401 || status === 400) {
                                        notify.show('Email or Password Incorrect!', 'warning', 5000);
                                    } else if (status === 203) {
                                        notify.show(login.message, 'error', 5000);
                                        setTimeout(() => {
                                            this.props.history.push('/signup/shipper', { contact_email: values.email })
                                        }, 2000)

                                    } else {
                                        notify.show(login.message, 'error', 5000);
                                    }
                                } catch (err) {

                                    notify.show(`Invalid Password!!!`, 'error', 5000);
                                }

                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form className="form">
                                    <div className="form-group has-feedback">
                                        <label>Email Address</label>
                                        <Field type="email" className="form-control" name="email" />
                                        <span
                                            className="glyphicon glyphicon-envelope form-control-feedback"
                                            aria-hidden="true"
                                        ></span>
                                        <ErrorMessage name="email" component="div" className="text-danger"
                                            style={{ fontSize: '12px' }} />
                                    </div>
                                    <div className="form-group has-feedback">
                                        <label>Password</label>
                                        <Field type="password" className="form-control" name="password" />
                                        <span
                                            className="glyphicon glyphicon-lock form-control-feedback"
                                            aria-hidden="true"
                                        ></span>
                                        <ErrorMessage name="password" component="div" className="text-danger"
                                            style={{ fontSize: '12px' }} />
                                    </div>


                                    <button type="submit" style={{ width: '100%', color: 'black' }}
                                        className="btn btn-success" disabled={isSubmitting}>
                                        Submit {isSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                    </button>
                                </Form>
                            )}
                        </Formik>

                        <br />
                        <p className="text-center">
                            Do not have an account? <Link to="/signup" style={{ color: 'black' }}>Sign up</Link>
                        </p>
                        <p className="text-center">
                            <Link to="/forgot_password" style={{ color: 'black' }}
                            >Forgotten Password?</Link>
                        </p>
                        <p className="text-center">
                            <Link to="/" style={{ color: 'black' }}
                            >Back To Home</Link>
                        </p>

                        <p className="text-center" style={{ fontSize: '12px', color: 'grey' }}>
                            By clicking on Log In, you agree to our terms & conditions and privacy
                            policy
                        </p>
                    </div>
                </div>

                <div className="col-lg-7 col-sm-12 login-right hidden-md hidden-sm hidden-xs">
                    <p></p>
                </div>

            </section>

        );
    }
}
