import React, { Component } from 'react';
import PageNavbar from '../components/PageNavbar';
import Footer from '../components/Footer';
import jhaki_logo from '../assets/images/logo2.png';
import { Link } from 'react-router-dom';
import shipper_bg_image from '../assets/images/shipper_bg.jpg';
import carrier_bg_image from '../assets/images/carrier_bg.jpg';
import hello_driver_1_image from '../assets/images/hello_driver_1.jpg';

export default class SignUp extends Component{
    render(){
        return (
            <div>
                <PageNavbar />

                <div className="options">
                    <div className="container">
                        <h2 className="text-center">Sign up options</h2>
                        <hr />
                        <div className="row">
                            <div className="col-md-4" style={{height:"420px"}}>
                                <img
                                src={shipper_bg_image}
                                className="img-circle img-responsive"
                                width="250"
                                style={{margin: '0 auto', display: 'block'}}
                                />
                                <h3 className="text-center"></h3>
                                <p className="text-center" style={{fontSize:"15px"}}>
                                Enjoy peace of mind with real-time tracking<br />
                                and insured carriers.
                                </p>
                                <p className="text-center">
                                    <Link to="/signup/shipper" className="btn btn-success">Shipper Sign Up</Link>
                                </p>
                            </div>
                            <div className="col-md-4" style={{height:"420px"}}>
                                <img
                                src={carrier_bg_image}
                                className="img-circle img-responsive"
                                width="250"
                                style={{margin: '0 auto', display: 'block'}}
                                />
                                <h3 className="text-center"></h3>
                                <p className="text-center" style={{fontSize:"15px"}}>
                                Find loads faster and easier than ever before<br />
                                across cities and earn more.
                                </p>
                                <p className="text-center">
                                    <Link to="/signup/carrier" className="btn btn-success">Carrier Sign Up</Link>
                                </p>
                            </div>

                            <div className="col-md-4" >
                                <img
                                src={hello_driver_1_image}
                                className="img-circle img-responsive"
                                width="250"
                                style={{margin: '0 auto', display: 'block'}}
                                />
                                <h3 className="text-center"></h3>
                                <p className="text-center" style={{fontSize:"15px"}}>
                                Better earnings, Reliable booking, fast payment<br />
                                and lifestyle rewards.
                                </p>
                                <p className="text-center">
                                <Link to="/signup/driver" className="btn btn-success">Driver Sign Up</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>

        );
    }
}