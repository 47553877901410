import React, { Component } from 'react';
import PageNavbar from '../components/PageNavbar';
import Footer from '../components/Footer';
import SignUpPhone from '../components/SignUpPhone';

import { Link } from 'react-router-dom';

import trucking_partner_plain_image from '../assets/images/trucking_partner_plain.png';
import barge_image from '../assets/images/barge.png';
import rail_for_web_plain_image from '../assets/images/rail_for_web_plain.png';
import a5_image from '../assets/images/A.5.png';
import a2_image from '../assets/images/A.2.png';
import a3_image from '../assets/images/A.3.png';
import a6_image from '../assets/images/A.6.png';
import a4_image from '../assets/images/A.4.png';
import a1_image from '../assets/images/A.1.png';

export default class Carrier extends Component {
    render() {
        return (
            <div>
                <PageNavbar />
                <section className="intro hidden-sm hidden-xs hidden-md" style={{ height: '100vh' }}>

                    <div className="col-lg-6 col-sm-12 new-carrier">
                        <p>

                        </p>
                    </div>
                    <div className="col-lg-6 col-sm-12 home-right">
                        <h1>
                            Great rates. One tap away.
                        </h1>
                        <p style={{marginRight:"7px"}}>
                            Thousands of carriers across several states use Jhaki’s free mobile app<br /> to increase their revenue, reduce deadhead miles<br /> and have visibility into their next load.</p>
                        <div className="app" style={{ marginTop: '170px', float: 'right', marginRight: '31px' }}>
                            <Link to="/signup/carrier" className="btn btn-success">Sign Up as Carrier </Link>
                        </div>
                        <button className="scroll-down" address="true"></button>
                    </div>
                </section>
                <div className="mobile-carrier hidden-lg" >
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8 text-center">
                            </div>

                            <div className="col-sm-2">

                            </div>

                        </div>
                    </div>
                </div>

                <div className="quote hidden-lg">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 text-center">
                                <h2 style={{ fontSize: '20px' }}>
                                    Great rates. One tap away.</h2>
                                <p className="text-center" style={{ fontSize: '12px' }}>
                                    Thousands of carriers across several states use Jhaki’s free mobile app
                                    <br /> to increase their revenue, reduce deadhead miles<br /> and have visibility into their next load.
                                </p>
                                <div className="col-xs-12">
                                    <div className="app" style={{ marginTop: '30px' }}>
                                        <p className="text-center">
                                            <Link to="/signup/carrier" className="btn btn-success">Sign Up as Carrier </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </div>

                <section className="featuress about hidden-md hidden-sm hidden-xs" style={{ height: '100%' }}>
                    <div className="" style={{padding:"0 60px "}} >

                        <div className="row" style={{marginLeft:"3px"}}>
                            <div className="col-md-6 newt">
                                <h2><span>Trucking Partner</span></h2>

                                <p style={{ textAlign: 'justify' }}>
                                    Do you have a Truck and want more consistent cashflow; Register now and become a Jhaki trucking partner! Enjoy Free, instant access to guaranteed quality loads from reputable companies and get paid instantly.<br /><br />
                                    As a trucking partner, you will receive a number of benefits including assurance of payments, consistent monthly
                                    cargo turn-over,transparent pricing and fuel cards.<br /><br />

                                    Connect instantly with prescreened, quality shippers on Jhaki and Make more money faster, spending less effort and time.
                                </p>
                                <br />
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                            <div className="col-md-6">

                            </div>

                        </div>
                    </div>
                </section>
                <div className="featuresss hidden-md hidden-sm hidden-xs" style={{ height: '100%' }}>
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row" >
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6 newt">
                                <h1>Barge Partner</h1>
                                <p style={{ textAlign: 'justify' }}>
                                    Given the huge potential of the inland water ways transportation industry, we're looking to develop new partnerships with professional barge operators.
                                    Make more money in less time and find loads faster using Jhaki to connect with quality shippers instantly.<br /><br />Become a Verified Barge Operator partner by signing up and enjoy access to leading shippers across a wide range of industries,
                                    including consumer-packaged goods, food and beverages,Agro-commodities and construction materials.From pick up to payday, the Jhaki app is your all-in-one tool to book loads faster.
                                </p>
                                <br />
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="featuressss hidden-md hidden-sm hidden-xs" style={{ height: '100%' }}>
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row" style={{marginLeft:"3px"}}>
                            <div className="col-md-6 newt">
                                <h1>Rail Partners</h1>
                                <p style={{ textAlign: 'justify' }}>
                                    When it comes to moving cargo, there are three options on Jhaki: road, rail, or water. While each has their own respective benefits,
                                    rail is one of the better options to choose thanks to its versatility and cost-effectiveness. Rail remains an untapped channel for moving heavy cargo to and from key cities in Nigeria linked by the railway Lines.<br /><br />

                                    Become a Verified Rail Freight partner by signing up and enjoy access to leading shippers across a wide range of industries, including consumer-packaged goods, food and beverages,Agro-commodities and construction materials. From pick up to payday, the Jhaki app is your all-in-one tool to book loads faster.
                                </p>

                                <br />
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                            <div className="col-md-6">

                            </div>

                        </div>
                    </div>
                </div>


                <section id='jump' className="features about hidden-lg" style={{ backgroundColor: '#fbfbfb' }}>
                    <div className="" style={{padding:"0 60px "}}>

                        <div className="row">
                            <div className="col-md-6 col-md-push-6">
                                <img src={trucking_partner_plain_image} alt="Jhaki" className="img-responsive checkout--bounce" />
                            </div>
                            <div className="col-md-6 col-md-pull-6">
                                <h2><span style={{ color: '#21ad21', fontWeight: 500 }}>
                                    Trucking Partner</span></h2>

                                <p style={{ textAlign: 'justify' }}>
                                    Do you have a Truck and want more consistent cashflow; Register now and become a Jhaki trucking partner! Enjoy Free, instant access to guaranteed quality loads from reputable companies and get paid instantly.<br /><br />
                                    As a trucking partner, you will receive a number of benefits including assurance of payments, consistent monthly
                                    cargo turn-over,transparent pricing and fuel cards.<br /><br />

                                    Connect instantly with prescreened, quality shippers on Jhaki and Make more money faster, spending less effort and time.
                                </p>

                                <br />
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>


                        </div>
                    </div>
                </section>
                <div className="features hidden-lg">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={barge_image} className="img-responsive checkout--bounce" alt="jhaki" width="" style={{ margin: '0 auto', display: 'block' }} />
                            </div>
                            <div className="col-md-6">
                                <h1><span style={{ color: '#21ad21', fontWeight: 500 }}>Barge Partner</span></h1>
                                <p style={{ textAlign: 'justify' }}>
                                    Given the huge potential of the inland water ways transportation industry, we're looking to develop new partnerships with professional barge operators.
                                    Make more money in less time and find loads faster using Jhaki to connect with quality shippers instantly.<br /><br />Become a Verified Barge Operator partner by signing up and enjoy access to leading shippers across a wide range of industries,
                                    including consumer-packaged goods, food and beverages,Agro-commodities and construction materials.From pick up to payday, the Jhaki app is your all-in-one tool to book loads faster.
                                </p>
                                <br />
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features hidden-lg" style={{ backgroundColor: '#fbfbfb' }}>
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={rail_for_web_plain_image} className="img-responsive checkout--bounce" alt="jhaki" width="" style={{ margin: '0 auto', display: 'block' }} />
                            </div>
                            <div className="col-md-6">
                                <h1><span style={{ color: '#21ad21', fontWeight: 500 }}>
                                    Rail Partners</span></h1>
                                <p style={{ textAlign: 'justify' }}>
                                    When it comes to moving cargo, there are three options on Jhaki: road, rail, or water. While each has their own respective benefits,
                                    rail is one of the better options to choose thanks to its versatility and cost-effectiveness. Rail remains an untapped channel for moving heavy cargo to and from key cities in Nigeria linked by the railway Lines.<br /><br />

                                    Become a Verified Rail Freight partner by signing up and enjoy access to leading shippers across a wide range of industries, including consumer-packaged goods, food and beverages,Agro-commodities and construction materials. From pick up to payday, the Jhaki app is your all-in-one tool to book loads faster.</p>

                                <br />
                                <Link to="/signup/carrier" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="becomess" style={{ backgroundColor: '#1e1d1d', padding: '70px 0' }}>
                    <h1 className="text-center" style={{ color: 'white',fontWeight:"600" }}>Why choose us</h1>
                    <br />
                    <div className="" style={{padding:"0 40px "}}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="col-sm-4 med" style={{ height: '190px' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="media-object" src={a5_image} alt="jhaki" width="50" />
                                        </div>
                                        <div className="media-body">
                                            <h4 style={{ fontSize: '20px' }} className="media-heading">Hassle Free Booking</h4>
                                            <p style={{ textAlign: 'justify', fontSize: '14px', fontWeight: 400, color: 'white' }}>Get access to loads from leading shippers across a wide range of industries, including consumer-packaged goods, food and beverages,Agro-commodities and construction materials.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4" style={{ height: '190px' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="media-object" src={a2_image} alt="jhaki" width="50" />
                                        </div>
                                        <div className="media-body">
                                            <h4 style={{ fontSize: '20px' }} className="media-heading">Instant Payment</h4>
                                            <p style={{ textAlign: 'justify', fontSize: '14px', fontWeight: 400, color: 'white' }} >Submit a Proof of Delivery right in the app and get paid instantly. No stories. No hidden charges.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" style={{ height: '190px' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="media-object" src={a3_image} alt="jhaki" width="50" />
                                        </div>
                                        <div className="media-body">
                                            <h4 style={{ fontSize: '20px' }} className="media-heading">App is free to use</h4>
                                            <p style={{ textAlign: 'justify', fontSize: '14px', fontWeight: 400, color: 'white' }}>The Jhaki app is free to download and works on any Android device</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 adjust">
                                <div className="col-sm-4 med" style={{ height: '190px' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="media-object" src={a6_image} alt="jhaki" width="50" />

                                        </div>
                                        <div className="media-body">
                                            <h4 style={{ fontSize: '20px' }} className="media-heading">Don't Drive Empty Miles</h4>
                                            <p style={{ textAlign: 'justify', fontSize: '14px', fontWeight: 400, color: 'white' }} >New loads are available every day, and booking them only takes seconds. Get instant confirmation and load details. No Dulling</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4" style={{ height: '190px' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="media-object" src={a4_image} alt="jhaki" width="50" />
                                        </div>
                                        <div className="media-body">
                                            <h4 style={{ fontSize: '20px' }} className="media-heading">Quick Issue Resolution</h4>
                                            <p style={{ textAlign: 'justify', fontSize: '14px', fontWeight: 400, color: 'white' }}>Quick and effective resolution process for all in-transit issues so that your business never stops</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 med">
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="media-object" src={a1_image} alt="jhaki" width="50" />
                                        </div>
                                        <div className="media-body">
                                            <h4 style={{ fontSize: '20px' }} className="media-heading">Transparency</h4>
                                            <p style={{ textAlign: 'justify', fontSize: '14px', fontWeight: 400, color: 'white' }}>View entire price shipper pays to us, while knowing the percentage you are getting from the process.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SignUpPhone title="Sign up for free and start hauling today." />

                <Footer />
            </div>
        );
    }
}