import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import instant_pricing_image from '../assets/images/instant_pricing.png';
import daily_loads_image from '../assets/images/daily_loads.png';
import develop_image from '../assets/images/develop.png';
import jhaki_screenshot_image from '../assets/images/jhaki_screenshot.png';
import driver_web_version_image from '../assets/images/driver-web-version.png';
import manage_image from '../assets/images/manage.png';
import shipper_mobile3_image from '../assets/images/shipper_mobile3.png';
import right_arrow_circular_button_image from '../assets/images/right-arrow-circular-button.png';
import signup_phone_image from '../assets/images/signup_phone.png';
import carrier_image from '../assets/images/carrier.png';
import ship_icon_image from '../assets/images/ship_icon.png';
import drivers_image from '../assets/images/drivers.png';

import '../assets/css/main_page.css';
import { Link } from 'react-router-dom';
import Service from '../Service';

function abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = Math.round(number * decPlaces / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if ((number == 1000) && (i < abbrev.length - 1)) {
                number = 1;
                i++;
            }

            // Add the letter for the abbreviation
            number += abbrev[i];

            // We are done... stop
            break;
        }
    }

    return number;
}

export default class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            landing_stats: {}
        }
    }
    async componentDidMount() {
        var $els = $('div[id^=quote]'),
            i = 0,
            len = $els.length;

        $els.slice(1).hide();
        setInterval(function () {
            $els.eq(i).fadeOut(function () {
                i = (i + 1) % len
                $els.eq(i).fadeIn();
            })
        }, 7000)

        let { data: response } = await Service.getLandingStats();
        if (response.data) {
            await this.setState({
                landing_stats: response.data
            })
            $('.counter').each(function () {
                var $this = $(this),
                    countTo = $this.attr('data-count');

                $({ countNum: $this.text() }).animate({
                    countNum: countTo
                },

                    {

                        duration: 8000,
                        easing: 'linear',
                        step: function () {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function () {
                            $this.text(abbrNum(this.countNum, 2));

                        }

                    });

            });
        }
    }
    render() {
        return (
            <div>
                <Navbar />
                <div className="big-wrapper">
                    <div className="" >
                        <div className="col-md-4 col-sm-6 overlay" >

                            <div id="quote1">
                                <h1>
                                    Moving cargo doesn’t have to be a nightmare
                                </h1>
                                <p style={{ textAlign: 'justify', fontSize: "16px" }}>
                                    Jhaki is a platform that connects shippers with carriers. With Jhaki's
                                    transparent pricing structure, real-time shipment tracking and a rich pool of reliable carriers,
                                    we
                                    are reinventing freight transportation in Nigeria and Sub-saharan Africa.
                                </p>
                                <br />
                            </div>

                            <div id="quote2">
                                <h1>Real-Time Container Tracking</h1>
                                <p style={{ textAlign: 'justify', fontSize: "16px" }}>
                                    Jhaki pinpoints the exact location of our drivers to provide you with best container tracking and
                                    real-time notifications to monitor your container at every mile.
                                </p>
                            </div>

                            <div id="quote3">
                                <h1>Trusted carriers to get the job done</h1>
                                <p style={{ textAlign: 'justify', fontSize: "16px" }}>
                                    Jhaki ensures the safety of your cargo by providing you with pre-vetted and experienced
                                    carriers.
                                </p>
                            </div>

                            <button className="scroll-down" address="true" style={{ position: 'absolute', top: '300px' }}></button>
                        </div>
                        <div className="col-md-8"></div>
                    </div>
                </div>
                <section className="products about">
                    <div className='' style={{padding:"0 60px"}}>
                        <div className="row">

                            <div className="col-md-4 col-sm-4 text-center" style={{ height: "240px" }}>
                                <img src={instant_pricing_image} className="img-responsive"
                                    style={{ display: 'block', margin: '0 auto' }}
                                    width="100" alt='jhaki' />
                                <h2 style={{ fontWeight: "500" }}>Instant Pricing</h2>
                                <p style={{ textAlign: 'center', fontWeight: 400, fontSize: '15px' }}>
                                    Get a quote and book the load instantly. Fair and transparent pricing on every load and guarantee<br /> we’ll get the job done
                                    at that rate.
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center" style={{ height: "240px" }}>
                                <img src={daily_loads_image} className="img-responsive"
                                    style={{ display: 'block', margin: '0 auto' }}
                                    width="100" alt='jhaki' />
                                <h2 style={{ fontWeight: "500" }}>Daily Loads</h2>
                                <p style={{ textAalign: 'center', fontWeight: 400, fontSize: '15px' }}>
                                    Presence in key locations in Nigeria generating
                                    <br />hundreds of loads every day, and
                                    <br /> at the right price.
                                </p>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center" style={{ height: "240px" }}>
                                <img src={develop_image} className="img-responsive"
                                    style={{ display: 'block', margin: '0 auto' }} width="80"
                                    alt="jhaki" />
                                <h2 style={{ fontWeight: "500" }}>Reliable Service</h2>
                                <p style={{ textAlign: 'center', fontWeight: 400, fontSize: '15px' }}>
                                    Experience seamless service delivery with Real-time tracking of your shipment and 24/7
                                    <br />customer service support.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="features">
                    <div className="" style={{ padding: "0 60px" }}>
                        <div className="row">
                            <div className="col-md-6 col-md-push-6">
                                <img src={jhaki_screenshot_image} className="img-responsive checkout--bounce"
                                    alt='jhaki' />
                            </div>
                            <div className="col-md-6 col-md-pull-6">
                                <h1><span style={{ color: '#21ad21', fontWeight: 500 }}>Shippers</span></h1>

                                <p style={{ textAlign: 'justify' }}>
                                    Whether you are a local importer or large manufacturer, Jhaki provides
                                    you a platform to find reliable carriers and manage all your shipments on one smart and
                                    convenient
                                    platform.
                                </p>
                                <p style={{ textAlign: 'justify' }}>Enjoy hassle free booking, no wahala pricing and absolute peace of
                                    mind
                                    with real-time monitoring of your shipment at every mile.
                                </p>
                                <br />
                                <Link to="/shipper" className="btn btn-success">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about features">
                    <div className="" style={{ padding: "0 60px" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={manage_image} className="img-responsive checkout--bounce"
                                    style={{ margin: '0 auto', display: 'block' }} alt='jhaki' />
                            </div>
                            <div className="col-md-6">
                                <h1>
                                    <span style={{ color: '#21ad21', fontWeight: 500 }}>Carriers</span>
                                </h1>
                                <p style={{ textAlign: 'justify' }}>
                                    Find and book daily loads all year round for your fleet with ease
                                    and
                                    watch your "pepper rest" after a job is completed.
                                </p>

                                <p style={{ textAlign: 'justify' }}>
                                    Whether you are a single truck owner looking for frequent trips or a
                                    fleet operator looking to expand, partner with goJhaki.com and enjoy no wahala pricing and
                                    efficient
                                    route planning that helps optimise time and reduce fuel expenses of your fleet.
                                </p>
                                <br />
                                <Link to="/carrier" className="btn btn-success">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features">
                    <div className="" style={{ padding: "0 60px" }}>
                        <div className="row">
                            <div className="col-md-6 col-md-push-6">
                                <img src={driver_web_version_image} className="img-responsive checkout--bounce"
                                    alt="jhaki" width=""
                                    style={{ margin: '0 auto', display: 'block' }} />
                            </div>
                            <div className="col-md-6 col-md-pull-6">
                                <h1><span style={{ color: '#21ad21', fontWeight: 500 }}>Drivers</span></h1>
                                <p style={{ textAlign: 'justify' }}>
                                    Life on the road can be tough but with Jhaki it gets easier.With
                                    Jhaki's
                                    reliable booking, fast payment and lifestyle rewards you will enjoy increased earning and a
                                    better
                                    life.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Our 24/7 support team dey kampe with you from the pickup point to your
                                    final destination to ensure no wahala disturbs your trip.
                                </p>
                                <br />
                                <Link to="/driver" className="btn btn-success">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how" style={{ backgroundColor: '#fbfbfb', padding: '50px 0' }}>
                    <h2 className="text-center">A guide on how to use Jhaki</h2>
                    <p style={{ textAlign: 'center', fontSize: "16px" }}>
                        Whether you’re looking for a means to move your cargo across
                        <br /> towns or cities
                        far from home, getting there should be easy.
                        <br /> Learn how to book with the Jhaki app.
                    </p>
                    <div id="carousel-how" className="carousel slide" data-ride="carousel" data-interval='false'>

                        <ol className="carousel-indicators">
                            <li data-target="#carousel-how" data-slide-to="0" className="active"></li>
                            <li data-target="#carousel-how" data-slide-to="1"></li>
                            <li data-target="#carousel-how" data-slide-to="2"></li>
                        </ol>

                        <div className="carousel-inner" role="listbox">
                            <div className="item active">
                                <div className="">
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4" style={{ marginTop: '20px' }}>
                                            <img src={shipper_mobile3_image} className="img-responsive" width="300"
                                                alt="jhaki"
                                                style={{ display: 'block', margin: '0 auto' }} />
                                        </div>
                                        <div className="col-sm-4 adjust">
                                            <h2>
                                                Ready to move a load? Open the app, specifiy shipping details, and book a carrier
                                                with
                                                just a tap.
                                            </h2>
                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="" style={{ padding: "0 60px" }}>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4" style={{ marginTop: '20px' }}>
                                            <img src={shipper_mobile3_image} className="img-responsive" width="300"
                                                alt="jhaki"
                                                style={{ display: 'block', margin: '0 auto' }} />
                                        </div>
                                        <div className="col-sm-4 adjust">
                                            <h2>Tap to book new loads. Available everyday with instant confirmation.</h2>
                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="" style={{ padding: "0 60px" }}>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4" style={{ marginTop: '20px' }}>
                                            <img src={shipper_mobile3_image} className="img-responsive" width="300"
                                                alt="jhaki"
                                                style={{ display: 'block', margin: '0 auto' }} />
                                        </div>
                                        <div className="col-sm-4 adjust">
                                            <h2>Track your cargo in real-time from the comfort of your office or home.</h2>
                                        </div>
                                        <div className="col-sm-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a className="right carousel-control" href="#carousel-how" role="button" data-slide="next">
                            <span aria-hidden="true">
                                <img src={right_arrow_circular_button_image}
                                    className="img-responsive" width="70"
                                    style={{ marginTop: '200px' }} alt="Jhaki_Arrow_right" />
                            </span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="cta-desktop hidden-sm hidden-md hidden-xs"
                    style={{ height: '400px', backgroundColor: '#000', overflow: 'hidden' }}>
                    <div className="" style={{ padding: "0 60px" }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 style={{ color: 'white', textAlign: 'justify', paddingTop: '50px' }}>
                                    <img src={signup_phone_image} style={{ margin: '0 auto', display: 'block' }}
                                        alt="jhaki"
                                        className="img-responsive" width="500" />
                                </h3>
                            </div>
                            <div className="col-lg-6" style={{ paddingTop: '100px' }}>
                                <h2 style={{ fontSize: '45px', fontWeight: 900, color: 'white' }}>Sign up for free and start shipping
                                    today.
                                </h2>
                                <Link to="/signup/shipper" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cta-mobile hidden-lg" style={{ backgroundColor: '#000', textAlign: 'center', padding: '50px 0' }}>
                    <div className="" style={{ padding: "0 60px" }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 style={{ fontSize: '30px', fontWeight: 900, color: 'white' }}>
                                    Sign up for free and start shipping today.
                                </h2>
                                <Link to="/signup/shipper" className="btn btn-success">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="stat">
                    <div className="" style={{ padding: "0 60px" }}>
                        <h2 className="text-center" style={{ color: '#000', fontWeight: 800 }}>Our Statistics</h2>
                        <div className="row">
                            <div className="col-md-3 col-sm-6 text-center">
                                <h3 className="stat-num counter" data-count={this.state.landing_stats.shipper}>0</h3>
                                <p className="stat-text">Shippers</p>
                            </div>
                            <div className="col-md-3 col-sm-6 text-center">
                                <h3 className="stat-num counter" data-count={this.state.landing_stats.teu}>0</h3>
                                <p className="stat-text">TEUs</p>
                            </div>
                            <div className="col-md-3 col-sm-6 text-center">
                                <h3 className="stat-num counter" data-count={this.state.landing_stats.carrier}>0</h3>
                                <p className="stat-text">Carriers</p>
                            </div>
                            <div className="col-md-3 col-sm-6 text-center">
                                <h3 className="stat-num counter" data-count={this.state.landing_stats.tonnage}>0</h3>
                                <p className="stat-text">Tonnage</p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="become about">
                    <div className="" style={{ padding: "0 60px" }}>
                        <div className="row vdivide">

                            <div className="col-md-4 col-sm-4 cols">

                                <img src={carrier_image} className="img-responsive"
                                    style={{ display: 'block', margin: '0 auto' }} alt="jhaki"
                                    width="80" />

                                <h3>Carriers</h3>
                                <p style={{ fontWeight: 400, fontSize: '14px' }}>Find loads faster and easier than ever <br />before
                                    across
                                    cities and earn more.
                                </p>
                                <Link to="/signup/carrier" className="btn btn-success" style={{ fontSize: '14px' }}>Become a Carrier</Link>
                            </div>
                            <div className="col-md-4 col-sm-4 cols">
                                <img src={ship_icon_image} className="img-responsive"
                                    style={{ display: 'block', margin: '0 auto' }}
                                    alt="jhaki" width="80" />
                                <h3>Shippers</h3>
                                <p style={{ fontWeight: 400, fontSize: '14px' }}>
                                    Enjoy peace of mind with real-time
                                    <br />tracking and insured carriers.
                                </p>
                                <Link to="/signup/shipper" className="btn btn-success" style={{ fontSize: '14px' }}>Become a Shipper</Link>
                            </div>
                            <div className="col-md-4 col-sm-4 cols">
                                <img src={drivers_image} className="img-responsive"
                                    style={{ display: 'block', margin: '0 auto' }}
                                    alt="jhaki" width="80" />
                                <h3>Drivers</h3>
                                <p style={{ fontWeight: 400, fontSize: '14px' }}>Better earnings, Reliable booking, <br />fast payment
                                    and
                                    lifestyle rewards.</p>
                                <Link to="/signup/driver" className="btn btn-success" style={{ fontSize: '14px' }}>Become a Driver</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}
