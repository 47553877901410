import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import jhaki_logo from '../assets/images/logo.png'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import Service from "../Service";
import Notifications, { notify } from 'react-notify-toast';

export default class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_type: null
        }
    }
    componentDidMount() {
        if (this.props.match.params.type && (this.props.match.params.type == 'shipper' || this.props.match.params.type == 'driver' || this.props.match.params.type == 'carrier')) {
            this.setState({
                form_type: this.props.match.params.type
            });

        }
        else {
            this.history.push('/')
        }

    }
    render() {
        // const this.state.form_type = this.props.match.params.type;
        const bg_classes = [
            'shipper-right',
            'driver-right',
            'carrier-right'
        ]
        const bg_class = this.state.form_type == 'shipper' ? bg_classes[0] : this.state.form_type == 'driver' ? bg_classes[1] : bg_classes[2];
        const form_title = this.state.form_type == 'shipper' ? 'Shipper' : this.state.form_type == 'driver' ? 'Driver' : 'Carrier';

        const schema_object = {
            contact_email: Yup.string().email('Invalid email').required('Email is required'),
            password: Yup.string().required('Password is required'),
            confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm password field is required'),
            contact_name: Yup.string().required('Contact name is required'),
            contact_phone: Yup.number().typeError('Enter valid phone number').required('Phone number is required'),
            role: this.state.form_type == 'carrier' ? Yup.string().required('Please select a partner type') : Yup.string().notRequired()
        }

        const signUpSchema = Yup.object().shape(schema_object);

        return (
            <div>
                <section className="intro-carrier hidden-md hidden-sm hidden-xs" style={{ height: '100vh' }}>
                    <Notifications />
                    <div className="col-lg-5 col-sm-12 carrier-left">
                        <div className="col-sm-10">
                            <Link to="/">
                                <img src={jhaki_logo} className="img-responsive" alt="jhaki" width="150"
                                    style={{ margin: '0 auto', marginTop: "100px", display: 'block' }} />
                            </Link>
                            <h3 style={{ color: 'black', textAlign: 'center' }}>Signup as a {form_title} below</h3>

                            <Formik
                                initialValues={{
                                    contact_email: this.props.location && this.props.location.state && this.props.location.state.contact_email ? this.props.location.state.contact_email : '',
                                    password: '',
                                    confirm_password: '',
                                    contact_phone: '',
                                    role: '',
                                    contact_name: ''
                                }}
                                validationSchema={signUpSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    try {

                                        values.role = this.state.form_type == 'shipper' ? '0' : this.state.form_type == 'driver' ? '1' : values.role;
                                        let { data: response } = await Service.registerUser(values);
                                        console.log(response)
                                        if (response.auth) {
                                            notify.show(response.message, 'warning', 4000);
                                            this.props.history.push(`/login`, { contact_email: values.contact_email });
                                            return;
                                        }
                                        else if (response.data) {
                                            notify.show('Account Created Successfully!', 'success', 4000);
                                            this.props.history.push(`/otp`, { user: JSON.stringify(response.data) });
                                            return;
                                        }
                                        else {
                                            notify.show(response.message, 'error', 4000);
                                        }
                                    }
                                    catch (err) {
                                        notify.show('An error occured! Please try again', 'error', 5000);
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="form">
                                        <div className="form-group has-feedback">
                                            <label>Contact Name</label>
                                            <Field type="text" className="form-control" name="contact_name" />
                                            <span className="glyphicon glyphicon-user form-control-feedback" aria-hidden="true"></span>
                                            <ErrorMessage name="contact_name" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                        </div>
                                        <div className="form-group has-feedback">
                                            <label>Email Address</label>
                                            <Field type="email" className="form-control" name='contact_email' />
                                            <span className="glyphicon glyphicon-envelope form-control-feedback" aria-hidden="true"></span>
                                            <ErrorMessage name="contact_email" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                        </div>
                                        <div className="form-group has-feedback">
                                            <label>Phone Number</label>
                                            <Field type="tel" className="form-control" name='contact_phone' />
                                            <span className="glyphicon glyphicon-phone form-control-feedback" aria-hidden="true"></span>
                                            <ErrorMessage name="contact_phone" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                        </div>
                                        {this.state.form_type == 'carrier' &&
                                            <div className="form-group has-feedback">
                                                <label>Select Partner:</label>
                                                <Field as="select" className="form-control" name="role" >
                                                    <option>Select a partner</option>
                                                    <option value="2">Trucking Partner</option>
                                                    <option value="3">Barge Partner</option>
                                                    <option value="4">Rail Partner</option>
                                                </Field>
                                                <span className="glyphicon glyphicon-user form-control-feedback" aria-hidden="true"></span>
                                                <ErrorMessage name="role" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                            </div>
                                        }
                                        <div className="form-group has-feedback">
                                            <label>Password</label>
                                            <Field type="password" className="form-control" name='password' />
                                            <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                            <ErrorMessage name="password" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                        </div>
                                        <div className="form-group has-feedback">
                                            <label>Confirm Password</label>
                                            <Field type="password" className="form-control" name='confirm_password' />
                                            <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                            <ErrorMessage name="confirm_password" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                        </div>

                                        <button type="submit" disabled={isSubmitting} className="btn btn-success" name="register" style={{ width: '100%' }}>Next</button>
                                        <br />

                                    </Form>
                                )}
                            </Formik>

                            <br />
                            <p className="text-center" style={{ fontSize: '10px', color: 'grey' }}>By clicking on Sign up, you agree
                                to our terms & conditions and privacy policy</p>
                            <p className="text-center"><Link to="/">Back To Home Page</Link></p>
                            <p className="text-center">Already have an account? <Link to='/login'>Login</Link></p>
                            <p style={{ fontSize: '12px', textAlign: "center" }}>Copyright © 2019 Jhaki Technologies. All rights
                                reserved.</p>
                        </div>

                    </div>

                    <div className={`col-lg-7 col-sm-12 ${bg_class} hidden-md hidden-sm hidden-xs tpad`}>

                    </div>

                </section>
                <div className="introduction hidden-lg" style={{ paddingTop: '40px' }}>
                    <Notifications />
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <img src={jhaki_logo} className="img-responsive" alt="jhaki" width="150"
                                    style={{ margin: '0 auto', display: 'block' }} />
                                <h3 style={{ color: 'black', textAlign: 'center' }}>Signup as a {form_title} below</h3>


                                <Formik
                                    initialValues={{
                                        contact_email: '',
                                        password: '',
                                        confirm_password: '',
                                        contact_phone: '',
                                        role: '',
                                        contact_name: ''
                                    }}
                                    validationSchema={signUpSchema}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        try {
                                            values.role = this.state.form_type == 'shipper' ? '0' : this.state.form_type == 'driver' ? '1' : values.role;
                                            let { data: response } = await Service.registerUser(values);

                                            if (response.auth) {
                                                notify.show(response.message, 'warning', 4000);
                                                this.props.history.push(`/login`, { contact_email: values.contact_email });
                                                return;
                                            }
                                            else if (response.data) {
                                                notify.show('Account Created Successfully!', 'success', 4000);
                                                this.props.history.push(`/otp`, { user: JSON.stringify(response.data) });
                                                return;
                                            }
                                            else {
                                                notify.show(response.message, 'error', 4000);
                                            }
                                        }
                                        catch (err) {
                                            notify.show('An error occured! Please try again', 'error', 5000);
                                        }
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className="form">
                                            <div className="form-group has-feedback">
                                                <label>Contact Name</label>
                                                <Field type="text" className="form-control" name="contact_name" />
                                                <span className="glyphicon glyphicon-user form-control-feedback" aria-hidden="true"></span>
                                                <ErrorMessage name="contact_name" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                            </div>
                                            <div className="form-group has-feedback">
                                                <label>Email Address</label>
                                                <Field type="email" className="form-control" name='contact_email' />
                                                <span className="glyphicon glyphicon-envelope form-control-feedback" aria-hidden="true"></span>
                                                <ErrorMessage name="contact_email" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                            </div>
                                            <div className="form-group has-feedback">
                                                <label>Phone Number</label>
                                                <Field type="tel" className="form-control" name='contact_phone' />
                                                <span className="glyphicon glyphicon-phone form-control-feedback" aria-hidden="true"></span>
                                                <ErrorMessage name="contact_phone" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                            </div>
                                            {this.state.form_type == 'carrier' &&
                                                <div className="form-group has-feedback">
                                                    <label>Select Partner:</label>
                                                    <Field as="select" className="form-control" name="role" >
                                                        <option>Select a partner</option>
                                                        <option value="2">Trucking Partner</option>
                                                        <option value="3">Barge Partner</option>
                                                        <option value="4">Rail Partner</option>
                                                    </Field>
                                                    <span className="glyphicon glyphicon-user form-control-feedback" aria-hidden="true"></span>
                                                    <ErrorMessage name="role" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                                </div>
                                            }
                                            <div className="form-group has-feedback">
                                                <label>Password</label>
                                                <Field type="password" className="form-control" name='password' />
                                                <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                                <ErrorMessage name="password" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                            </div>
                                            <div className="form-group has-feedback">
                                                <label>Confirm Password</label>
                                                <Field type="password" className="form-control" name='confirm_password' />
                                                <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                                <ErrorMessage name="confirm_password" component="div" className="text-danger" style={{ fontSize: '12px' }} />
                                            </div>

                                            <button type="submit" disabled={isSubmitting} className="btn btn-success" name="register" style={{ width: '100%' }}>Next</button>
                                            <br />

                                        </Form>
                                    )}
                                </Formik>
                                <p className="text-center"><Link to="/">Back To Home Page</Link></p>
                                <p className="text-center">Already have an account? <Link to="/login">Login</Link></p>
                            </div>
                            <div style={{ padding: '40px 0', textAlign: 'center' }}>
                                <p style={{ bottom: 0, fontSize: '10px' }}>Copyright © 2019 Jhaki Technologies. All rights reserved.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
