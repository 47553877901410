import React, { Component } from 'react';
import '../assets/css/forgot_password.css';
import forgot_password_image from '../assets/images/forgot_password.png';
import jhaki_logo from '../assets/images/logo2.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Service from '../Service';

import { Link } from 'react-router-dom';

export default class ForgotPassword extends Component{
    state = {
        link_sent: false
    }
    render(){
        const schema_object = {
            email: Yup.string().email('Enter a valid email').required('Email is required')
        }
        
        const resetPasswordSchema = Yup.object().shape(schema_object);

        return (
            <div className="forgotten_pass" style={{height: '100vh'}}>
                <div className="" style={{padding:"0 60px "}}>
                    <div className="row forgotten">
                        <div className="col-md-7">
                            <img src={forgot_password_image} className="img-responsive" />
                        </div>
                        <div className="col-md-5 push-down">
                            {
                                this.state.link_sent &&
                                <div className='alert alert-success' role='alert'>
                                    <button type='button' className='close'>&times;</button>
                                    <div className='alert-icon contrast-alert'>
                                        <i className='fa fa-thumbs-up'></i>
                                    </div>
                                    <div className='alert-message'>
                                        <span><strong>A password reset link has been sent to your email!</strong></span>
                                    </div>
                                </div>
                            }
                            <img src={jhaki_logo} className="img-responsive" width="100" />
                            
                            <h1>Forgotten Password?</h1>
                            <Formik
                                initialValues={{
                                    email: ''
                                }}
                                validationSchema = {resetPasswordSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    try{
                                        let {data: response} = await Service.forgotPassword(values);
                                        if(!response.error){
                                            this.setState({
                                                link_sent: true
                                            })
                                        }
                                    }
                                    catch(err){

                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <div>
                                        {!this.state.link_sent &&
                                    
                                            <Form className="form">
                                            
                                                    <div className="form-group has-feedback">
                                                        <label>Email</label>
                                                        <Field type="email" className="form-control" name='email' />
                                                        <span className="glyphicon glyphicon-lock form-control-feedback" aria-hidden="true"></span>
                                                        <ErrorMessage name="email" component="div" className="text-danger" style={{fontSize: '12px'}} />
                                                    </div>
                                                    
                                                    <button type="submit" disabled={isSubmitting} className="btn btn-success" name="register" style={{width: '100%'}}>Recover Password</button>
                                                    <br/>
                                            </Form> 
                                        }
                                    </div>
                                )}
                            </Formik>
                            
                            <div className="" style={{paddingTop: '10px'}}>
                                <p className="text-center"><Link to="/login">Login</Link></p>
                                <p className="text-center">
                                    <span style={{color: '#fff'}}>Do not have an account?</span> <Link to="/signup">Sign up</Link>
                                </p>
                                <p className="text-center">
                                    <Link to="/">Back To Home</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}