import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default class FAQs extends Component{
    render(){
        return (
            <div>
                <Navbar />
                <div class="faq-content">
                    <div class="" >
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                            <div class="col-sm-2"></div>
                        </div>
                    </div>
                </div>

                <section class="faq-section">
                    <div class="" >
                        <div class="row">
                            <div class="col-sm-2">

                            </div>
                            <div class="col-sm-8">
                                <h2>Shippers</h2>
                                <hr/>
                                <h4>How do I sign on to the Jhaki platform for shippers?</h4>
                                <p>Once you’ve signed up through our website, you should have received an OTP from Jhaki. You can input the otp and access Jhaki. If you did not receive this OTP, please reach out to us at hello@gojhaki.com and we’ll be happy to help.</p>
                                <h4>How does Jhaki vet their carriers?</h4>
                                <p>Consistent with industry standards, we review carrier operating authority, safety rating and insurance using information from the police database and other factors.</p>
                                <h4>How do I pay?</h4>
                                <p>Jhaki offers her local and international clients, convenient ways to make payment for our service. This is to ensure that every client has maximum comfort when making payment.For local payments, mode of payment includes Bank Transfer, GT Pay and QuickTeller</p>
                                <h4>Why do I have to pay in advance?</h4>
                                <p>This advance payment plan has been raised for customer benefit as it ensures hassle-free transactions, it also ensures efficiency on our end.</p>
                                <h4>Is there any Insurance cover when booking through Jhaki?</h4>
                                <p>Yes, we provide optional insurance cover as an additional value added service option in partnership with AXA Mansard. You can contact our team for more information on this.</p>
                                <h4>What if there is damage to my cargo?</h4>
                                <p>Carriers are liable to shippers for loss or damage to cargo in accordance with applicable law. Jhaki has an internal team that helps facilitate resolution and support our shippers in the case of cargo loss or damage. This team will coordinate your claim communications with the carrier. Please contact hello@gojhaki.com to report a cargo claim.</p>
                                <h4>How are prices generated? Are they guaranteed?</h4>
                                <p>Prices are generated by Jhaki’s proprietary algorithm. We guarantee that we will cover the load at the price we quote.</p>
                                <h4>How are my card details stored? Are they safe?</h4>
                                <p>Your card details are safe. We do not store our client's card details. All customer card details are entered on a secure PCIDSS compliant page provided by payment processors and issuers. The page is further rendered over SSL and all data are encrypted with the TripleDES (3DES) standard.
                                We only store a token that will be used by the payment gateway.</p>
                                <h4>How do I reset my password for the Jhaki Freight platform for shippers?</h4>
                                <p>You can reset your password by following this link if you still have problems signing in, please reach out to us at hello@gojhaki.com, and we will help you.</p>
                                <h4>Which carriers are you working with?</h4>
                                <p>We work with carriers of all sizes, from those that move a few loads a week to larger companies with thousands of loads a day. Every day we’re activating new carriers and we’re excited about the amount of interest in our services. We do not disclose carriers names due to confidentiality clauses.</p>
                                <h4>How to Use the Dashboard</h4>
                                <p>This video shows you how to navigate the dashboard on the Jhaki platform for shippers. You’ll learn how to access tools that simplify your shipping, digitally track your shipments in real time and contact our 24/7 customer support.</p>
                                <h4>Tracking my shipment?</h4>
                                <p>Jhaki provides real-time GPS tracking for shipments moved through our mobile app and website platform. Watch this video to learn how to track your shipments moving with Jhaki.</p>
                                <h4>How to contact Customer Support?</h4>
                                <p>Jhaki for shippers automates the shipping process, but we’re always here for you 24/7. To contact us hello@gojhaki.com.</p>
                                <h4>Is the money paid into my wallet secured?</h4>
                                <p>Yes it is, all funds in your wallet is held with in an escrow account with our partner GT Bank</p>
                                <h4>Is there a money back guarantee?</h4>
                                <p>Yes there is. Your wallet gets refunded within 24 hours</p>
                                <h4>Do I need to make full payments before my booking is approved and posted?</h4>
                                <p>Yes you do, we require full payment before any booking goes live on the platform.</p>
                                <h4>How do I load the details of my cargo?</h4>
                                <p>Watch this video to know how to navigate the load details page on the Jhaki platform for shippers.</p>


                            </div>
                            <div class="col-sm-2">

                            </div>
                        </div>
                    </div>
                </section>
                <hr/>
                <section class="faq-section">
                    <div class="">
                        <div class="row">
                            <div class="col-sm-2">

                            </div>
                            <div class="col-sm-8">
                                <h2>Carriers</h2>
                                <hr/>
                                <h4>How do I sign in?</h4>
                                <p>On your Android you can sign in with the email and password created provided for you when you created your Jhaki account.</p>
                                <h4>What if I forgot my password?</h4>
                                <p>You can reset your password by following this link. You'll need to enter your email address associated with your Jhaki account. If you still have problems signing in, please reach out to us at hello@gojhaki.com, and we will help you.
                                Finding and booking loads</p>
                                <h4>How to search for loads?</h4>
                                <p>The Jhaki app has many features to help you filter and search loads. You can search by trailer type, location, pick up date and drop off date. You can also sort loads by short, regional, or long distances based on your preferences.</p>
                                <h4>How to book a load?</h4>
                                <p>Tap on the load to view its details. Scroll to the bottom of the load details to see a “Book load button”. Press the button and the load is booked. You’ll receive a rate confirmation in your email.</p>
                                <h4>How to share a load?</h4>
                                <p>Tap on the load that you want to share. Scroll to the bottom of the load details and select “Share this Load”. If the recipient has been activated on the Jhaki app, they will receive a link to the load. If not, they will receive an invitation to sign up with Jhaki.</p>
                                <h4>When and how do I receive the rate confirmation?</h4>
                                <p>You should receive a rate confirmation in your email inbox shortly after tapping the “Book load button”. Your rate confirmation email will also include your ‘load number’ which you’ll need to provide if you contact our support team. How to cancel a load if I can no longer make the pickup? Call the Jhaki support team as soon as possible. To do so, tap the phone icon in the top right of the Jhaki app.</p>

                                <h4>After delivery<br/>
                                How to submit a proof of delivery</h4>
                                <p>After tapping “ I’m Unloaded” when your trailer is empty, the app will prompt you to use your phone’s camera to take a photo of the signed Proof of Delivery. Take the photo. Be sure to capture a clear and legible image of your P.O.D. to prevent delays in payment. If you need to, retake it. Then, add pages as necessary.</p>



                            </div>
                            <div class="col-sm-2">

                            </div>
                        </div>
                    </div>
                </section>
                <hr/>
                <section class="faq-section">
                    <div class="">
                        <div class="row">
                            <div class="col-sm-2">

                            </div>
                            <div class="col-sm-8">
                                <h2>Drivers</h2>
                                <hr/>
                                <p>If you have your own Driver License and interested in being a driver on our platform, you can sign up here</p>
                                <h4>Can I sign up as a driver without having a truck?</h4>
                                <p>Yes you can, you will be assigned to trucks.</p>
                                <h4>What documents are needed for me to become a driver?</h4>
                                <p>* Drivers License</p>
                                <h4>When do I get paid?</h4>
                                <p>We pay carriers immediately after successfully submitting a proof of delivery and return of containers.</p>
                                <h4>Steps to Become a Driver</h4>
                                <p>Jhaki drivers must fulfil certain requirements to e considered for driving on the platform. To become a driver, please see the following steps: </p>
                                <p>Step 1: Register your profile
                                Visit the driver sign up page to register</p>
                                <p>Step 2: Attend training
                                Once you have completed your registration and your documents have been verified, you will receive an invitation to attend an onboarding session</p>
                                <p>Step 3: Get your vehicle accredited
                                Get your vehicle inspected at your nearest Jhaki facility</p>
                                <p>Step 4: Get activated</p>

                            </div>
                            <div class="col-sm-2">

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}
