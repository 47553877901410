import React, { Component } from 'react';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

export default class About extends Component{
    render(){
        return (
            <div>
                <NavBar />
                <div className="faq-content">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                                <h2>About Jhaki</h2>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                </div>
                <section className="faq-section">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-4">
                                <h2 className="hidden-xs hidden-sm">About</h2>
                            </div>
                            <div className="col-sm-8">
                                <h3>Who is Jhaki?</h3>
                                <p>Jhaki is a platform that connects shippers with carriers. With unmatched capabilities we reinventing freight transportation in Nigeria and sub-Saharan Africa 
                                with real-time tracking of cargo, transparent pricing and access to a rich pool of verified carriers.<br/>Our experienced professional team solves the industry's 
                                biggest problems using the best technology available in order to promote connections with carriers.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <hr/>
                <section className="faq-section">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-4">
                                <h2 className="hidden-xs hidden-sm">Mission</h2>
                            </div>
                            <div className="col-sm-8">
                                <h3>Jhaki's Mission</h3>
                                <p>
                                Our Mission is to transform the logistics industry by connecting shippers to the best, reliable and safest carriers by leveraging a robust technology platform to deliver technology platforms 
                                deliver reliability, efficiency and seamless experience
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <hr/>
                <section className="faq-section">
                    <div className="" style={{padding:"0 60px "}}>
                        <div className="row">
                            <div className="col-sm-4">
                                <h2 className="hidden-xs hidden-sm">Vision</h2>
                            </div>
                            <div className="col-sm-8">
                                <h3>Jhaki's Vision</h3>
                                <p>Our vision is to be Africa's No 1 Digital Intermodal Freight Network committed to accelerating movement of goods In Africa</p>

                                
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}