import React,  { Component } from 'react';
import { Link } from 'react-router-dom';
import PageNavbar from '../components/PageNavbar';
import Footer from '../components/Footer';
import SignUpPhone from '../components/SignUpPhone';

import '../assets/css/shipper_page.css';

import shipper_mobile3_image from '../assets/images/shipper_mobile3.png';
import shipper_mobile1_image from '../assets/images/shipper_mobile1.png';
import shipper_mobile2_image from '../assets/images/shipper_mobile2.png';

import client1_image from '../assets/images/client1.png';
import client2_image from '../assets/images/client2.png';
import client3_image from '../assets/images/client3.png';
import client4_image from '../assets/images/client4.png';
import client5_image from '../assets/images/client5.png';
import client6_image from '../assets/images/client6.png';
import client7_image from '../assets/images/client7.png';
import client8_image from '../assets/images/client8.png';
import client9_image from '../assets/images/client9.png';
import client10_image from '../assets/images/client10.png';
import client11_image from '../assets/images/client11.png';

export default class Shipper extends Component{
    render(){
        return (
            <div>
                <PageNavbar />
                <section className="intro hidden-sm hidden-xs hidden-md" style={{height: '100vh'}}>
                    <div className="col-lg-6 col-sm-12 new-shipper">
                        
                    </div>
                    <div className="col-lg-6 col-sm-12 home-right">
                        <h1>
                        Haul the way you want.
                        </h1>
                        <p style={{marginRight:"7px"}}>It’s time to go in a new direction. Jhaki has the tools to get the job done <br/>the right way. 
                        <span style={{color: '#21ad21'}}>Quickly. Efficiently. Transparently.</span> With every load,<br/> we’re helping carriers and shippers build a better way.</p>
                        <div className="app" style={{marginTop: '200px', float: 'right', marginRight: '31px'}}>
                            <Link to="/signup/shipper" className="btn btn-success">Sign Up as a Shipper</Link>
                        </div>
                        <button className="scroll-down" address="true"></button>
                    </div>
                </section>

                <div className="mobile-shipper hidden-lg">
                    <div className="" style={{padding:"0 65px "}}>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8 text-center">

                            </div>
                                
                            <div className="col-sm-2">
                                
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="quote hidden-lg">
                    <div className="" style={{padding:"0 65px "}}>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 text-center">
                                <h2 style={{fontSize: '20px'}}>Haul the way you want.</h2>
                                <p style={{textAlign: 'center', fontSize: '12px'}}>It’s time to go in a new direction. Jhaki has the tools to get the job done — the right way. Quickly. Efficiently. Transparently. With every load, we’re helping carriers and shippers build a better way.</p>
                        
                                <div className="col-xs-12">
                                    <div className="app" style={{marginTop: '30px'}}>
                                        <p className="text-center">
                                            <Link to="/signup/shipper" className="btn btn-success">Sign Up as Shipper</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </div>


                <section className="features about" style={{backgroundColor: '#fbfbfb'}}>
                    <div className="" style={{padding:"0 65px "}}>
                        <div className="row">
                                <div className="col-md-6 col-md-push-6">
                                <img src={shipper_mobile3_image} alt="jhaki" className="img-responsive checkout--bounce" width="250" style={{margin: '0 auto',display: 'block'}} />
                            </div>
                            <div className="col-md-6 col-md-pull-6">
                                <h2><span style={{color: '#21ad21', fontWeight: 500}}>Reliable Trucks</span></h2>
                                
                                <p style={{textAlign: 'justify'}}>Get coverage during critical capacity moments by accessing our network of 100,000+ drivers. We focus on delivering value to carriers so working with you is always their first choice.</p> 
                                <h2><span style={{color: '#21ad21', fontWeight: 500}}>Full Transparency</span></h2>
                                
                                <p style={{textAlign: 'justify'}}>Keep tabs on your shipments with real-time GPS tracking — no need to pick up the phone to get the visibility you need.</p> 
                                <br/>
                                
                            </div>
                            

                        </div>
                    </div>
                </section>

                <div className="features">
                    <div className="" style={{padding:"0 65px "}}>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={shipper_mobile1_image} alt="jhaki" className="img-responsive checkout--bounce" width="250" style={{margin: '0 auto', display: 'block'}} />
                            </div>
                            <div className="col-md-6">
                                <h1><span style={{color: '#21ad21', fontWeight: 500}}>Actionable Data</span></h1>
                                <p style={{textAlign: 'justify'}}>See unique insights and trends based on your shipments, lanes, and region. Access all the data you need to save money and become a shipper of choice.</p>
                                
                                <h1><span style={{color: '#21ad21', fontWeight: 500}}>Instant Pricing</span></h1>
                                <p style={{textAlign: 'justify'}}>Get a quote and book the load instantly. We show upfront and transparent pricing on every load and guarantee we’ll get the job done at that rate.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features about" style={{backgroundColor: '#fbfbfb'}}>
                    <div className="" style={{padding:"0 65px "}}>
                            <div className="col-md-6 col-md-push-6">
                                <img src={shipper_mobile2_image} alt="jhaki" className="img-responsive checkout--bounce" width="250" style={{margin: '0 auto', display: 'block'}} />
                            </div>
                        <div className="row">
                            <div className="col-md-6 col-md-pull-6">
                                <h1><span style={{color: '#21ad21', fontWeight: 500}}>Haul with confidence</span></h1>
                                <p style={{textAlign: 'justify'}}>Anytime, anywhere, we’re there for you. With our 24/7 support, you’re always ready for the unexpected.</p>
                                <h1><span style={{color: '#21ad21', fontWeight: 500}}>Build bridges</span></h1>
                                <p style={{textAlign: 'justify'}}>Find the loads you want or the capacity you need — faster and easier than ever.</p>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>

                <SignUpPhone title="Sign up for free and start shipping today."/>
                <div className="branded">
                    <div className="" style={{padding:"0 65px "}}>
                        <br/>
                        <h1 style={{textAlign: 'center', textTransform: 'uppercase', fontWeight: 500, color: '#000'}}>Trusted by Leading Brands</h1>
                        <p style={{textAlign: 'center',fontSize:"14px"}}>Shippers of all sizes, from global companies to local manufacturers,<br/> trust Jhaki to move their cargo.</p>
                        <br/>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                                
                                <ol className="carousel-indicators">
                                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                                    <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                                </ol>

                                <div className="carousel-inner" role="listbox">
                                    <div className="item active">
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client1_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client2_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client3_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client4_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client5_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client6_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client7_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-3 col-xs-6">
                                            <img src={client8_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        
                                    </div>
                                    <div className="item">
                                        <div className="col-md-4 col-xs-6">
                                            <img src={client9_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-4 col-xs-6">
                                            <img src={client10_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                        <div className="col-md-4 col-xs-6">
                                            <img src={client11_image} alt="jhaki" className="img-responsive" style={{margin: '0 auto', display: 'block'}} width="150"/>
                                        </div>
                                    </div>   
                                </div>

                                <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                                    <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                                    <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}