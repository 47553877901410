import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import jhaki_logo from '../assets/images/logo.png';

export default class PageNavbar extends Component{
    render(){
        return (
            <nav className="navbar navbar-inverse navbar-fixed-top"  role='navigation'>
                <div className="container-fluid">
                
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar one"></span>
                            <span className="icon-bar two"></span>
                            <span className="icon-bar three"></span>
                        </button>
                        <Link to="/" className="navbar-brand" style={{marginTop: '-5px'}}>
                            <img src={jhaki_logo} className="img-responsive" width="100" alt="jhaki" />
                        </Link>
                    </div>

                
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right" >
                            <li>
                                <Link to="/carrier" style={{fontSize:"16px",fontWeight:"500"}}>Carrier</Link>
                            </li>
                            <li>
                                <Link to="/shipper" style={{fontSize:"16px",fontWeight:"500"}}>Shipper</Link>
                            </li>
                            <li>
                                <Link to="/driver" style={{fontSize:"16px",fontWeight:"500"}}>Become a Driver</Link>
                            </li>
                            <li>
                                <Link to="/estimate" style={{fontSize:"16px",fontWeight:"500"}}>Estimate</Link>
                            </li>
                            <li>
                                <Link to="/login" style={{fontSize:"16px",fontWeight:"500"}}>Login</Link>
                            </li>
                            <li>
                                <Link to="/signup" style={{fontSize:"16px",fontWeight:"500"}}>Sign Up</Link>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.app.user.jhaki"
                                    className="btn btn-primary btn-class"
                                    style={{backgroundColor: '#21ad21',borderRadius: 0,border: 'none',color: 'white !important', fontSize:"16px",fontWeight:"500"}}>Download
                                    App</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        );
    }
}