import React, { Component } from 'react';
import error_404_image from '../assets/images/404.png';
import '../assets/css/error_404_page.css';
import { Link } from 'react-router-dom';

export default class Error404 extends Component{
    render(){
        return (
            <div className="login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <img
                                src={error_404_image}
                                className="img-responsive"
                                style={{margin: '0 auto'}}
                            />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6 align-me">

                            <h3 style={{fontSize: '150px', fontWeight: 900, color: '#21ad21'}}>404</h3>
                            <h6 style={{fontSize: '50px', fontWeight: 900, color: '#333'}}>
                                Page Not Found
                            </h6>
                            <p style={{fontWeight: 400, fontSize: '15px', color: '#868585'}}>
                                This page doesn’t exist. But there are a bunch <br/>of other pages
                                waiting for you here
                            </p>
                            <br/>
                            <p>
                                <Link to="/" className="btn btn-success" style={{color: 'white'}}
                                >Back To Home</Link>
                        
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}